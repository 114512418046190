/* eslint-disable max-lines */
/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import WishlistQuery from 'Query/Wishlist.query';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    CLEAR_WISHLIST,
    clearWishlist,
    REMOVE_ITEM_FROM_WISHLIST,
    removeItemFromWishlist,
    UPDATE_ALL_PRODUCTS_IN_WISHLIST,
    updateAllProductsInWishlist,
    updateIsLoading
} from 'Store/Wishlist/Wishlist.action';
import { isWishlistEnabled } from 'Store/Wishlist/Wishlist.dispatcher';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { fetchMutation, fetchQuery } from 'Util/Request';
import { getQueryParam, updateQueryParamWithoutHistory } from 'Util/Url';
import { getPriceRange } from 'Util/Wishlist';

import { getCustomerData } from '../../data/customer';
import {
    addToWishlistEventHandler,
    fireRemoveAllWishlistItemsEvent,
    removeItemFromWishlistEventHandler,
    wishlistUpdateHandler
} from '../../event/wishlist';

const addFireAddToWishlistEventHandler = async (args, callback) => {
    if (!isSignedIn()) {
        return;
    }
    const [, options] = args;

    await callback(...args);
    // ^^^ wait for 2 queries inside callback
    addToWishlistEventHandler(options.items);
};

const addWishlistEventHandlers = (args, callback) => {
    const [state, action] = args;
    const { customerId, type } = action;

    // vvv for clear all button on MyAccount>Wishlist
    if (type === CLEAR_WISHLIST) {
        fireRemoveAllWishlistItemsEvent(customerId);
    }

    // vvv for heart button on PLP/PDP or etc.. and 'x' button on MyAccount>Wishlist
    if (type === REMOVE_ITEM_FROM_WISHLIST) {
        const { item_id: itemId } = action;
        const { productsInWishlist } = state;

        removeItemFromWishlistEventHandler(itemId, productsInWishlist, customerId);
    }

    // vvv for '+' and '-' button on MyAccount>Wishlist
    if (type === UPDATE_ALL_PRODUCTS_IN_WISHLIST) {
        const { products: newProductsInWishlist } = action;
        const { productsInWishlist } = state;

        wishlistUpdateHandler(newProductsInWishlist, productsInWishlist, customerId);
    }

    return callback(...args);
};

const aroundClearWishlist = (args) => {
    const [dispatch] = args;

    if (!isSignedIn()) {
        return Promise.reject();
    }

    dispatch(updateIsLoading(true));

    return fetchMutation(WishlistQuery.getClearWishlist())
        .then(
            () => {
                getCustomerData()
                    .then(
                        ({ customerId }) => {
                            dispatch(updateIsLoading(false));
                            return dispatch({
                                ...clearWishlist(),
                                customerId
                            });
                        }
                    )
                    .catch(
                        () => {
                            dispatch(updateIsLoading(false));
                            return dispatch({ ...clearWishlist(), customerId: null });
                        }
                    );
            }
        )
        .catch(
            () => {
                dispatch(updateIsLoading(false));
                return dispatch(showNotification('error', __('Error clearing wish list!')));
            }
        );
};

const aroundUpdateInitialWishlistData = async (args, _callback, instance) => {
    const [dispatch, page] = args;
    const { customerId } = await getCustomerData();

    if (isSignedIn() && isWishlistEnabled()) {
        instance._syncWishlistWithBE(dispatch, page);
    } else {
        dispatch(updateAllProductsInWishlist({}, customerId));
    }
};

const _getPageFromUrl = () => +(getQueryParam('page', location) || 1);

const aroundSyncWishlistWithBE = (args) => {
    const [dispatch, page] = args;
    const currentPage = _getPageFromUrl() || page;
    dispatch(updateIsLoading(true));
    // Need to get current wishlist from BE, update wishlist
    return fetchQuery(WishlistQuery.getWishlistQuery(__, currentPage)).then(
        (data) => {
            if (data && data.wishlist) {
                const { wishlist, wishlist: { page_info, items_count, items } } = data;
                const productsToAdd = wishlist.items.reduce((prev, wishlistItem) => {
                    const {
                        id,
                        sku,
                        product,
                        description,
                        price,
                        price_without_tax,
                        buy_request,
                        options,
                        qty: quantity
                    } = wishlistItem;

                    const priceRange = getPriceRange(product, price, price_without_tax);

                    return {
                        ...prev,
                        [id]: {
                            ...product,
                            ...priceRange,
                            quantity,
                            wishlist: {
                                id,
                                sku,
                                quantity,
                                description,
                                buy_request,
                                options
                            }
                        }
                    };
                }, {});

                getCustomerData()
                    .then(
                        ({ customerId }) => {
                            dispatch({
                                ...updateAllProductsInWishlist({ productsToAdd, page_info, items_count }),
                                customerId
                            });

                            if (!items.length && items_count > 0) {
                                updateQueryParamWithoutHistory('page', page_info.total_pages, history, location);
                            }
                        }
                    )
                    .catch(
                        () => dispatch({
                            ...updateAllProductsInWishlist({ productsToAdd, page_info, items_count }),
                            customerId: null
                        })
                    );
            } else {
                dispatch(updateIsLoading(false));
            }
        },
        /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then/catch */
        () => {
            dispatch(updateIsLoading(false));
        }
    );
};

const aroundRemoveItemFromWishlist = async (args) => {
    const [
        dispatch,
        { item_id, noMessages }
    ] = args;

    if (!item_id || !isSignedIn()) {
        return Promise.reject();
    }

    dispatch(updateIsLoading(true));

    try {
        await fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(item_id));
    } catch (e) {
        if (!noMessages) {
            dispatch(showNotification('error', __('Error updating wish list!')));
        }

        return Promise.reject();
    }

    const { customerId } = await getCustomerData();

    dispatch({ ...removeItemFromWishlist(item_id), customerId });

    if (!noMessages) {
        aroundSyncWishlistWithBE([dispatch]);
        dispatch(showNotification('success', __('Product has been removed from your Wish List!')));
    }

    return Promise.resolve();
};

export default {
    'Store/Wishlist/Dispatcher': {
        'member-function': {
            addItemToWishlist: addFireAddToWishlistEventHandler,
            clearWishlist: aroundClearWishlist,
            updateInitialWishlistData: aroundUpdateInitialWishlistData,
            _syncWishlistWithBE: aroundSyncWishlistWithBE,
            removeItemFromWishlist: aroundRemoveItemFromWishlist
        }
    },
    'Store/Wishlist/Reducer/WishlistReducer': {
        function: addWishlistEventHandlers
    }
};
