// TODO rename
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD';

// TODO rename
/** @namespace Tigerone/Store/Quotes/Action/setAddress */
export const setAddress = (address) => ({
    type: SET_ADDRESS,
    address
});
/** @namespace Tigerone/Store/Quotes/Action/setShippingMethod */
export const setShippingMethod = (method) => ({
    type: SET_SHIPPING_METHOD,
    method
});
