/* eslint-disable no-param-reassign */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import {
    LAST_ADDRESS_UPDATED,
    SET_CREDIT_CARD_DETAILS,
    SET_PAYMENT_METHOD,
    SET_SHIPPING_METHOD,
    SET_SHIPPING_METHODS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_SHIPPING_FIELDS,
    UPDATE_TOTALS_LOAD_STATUS
} from './Checkout.action';

/** @namespace Tigerone/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    shippingFields: {},
    all_shipping_methods: [],
    email: '',
    shipping_method_code: null,
    payment_method_code: null,
    isEmailAvailable: true,
    address: {
        isLastUpdated: false,
        address_id: 0
    },
    creditCardDetails: {},
    isLoading: false
});

/** @namespace Tigerone/Store/Checkout/Reducer/setCardDetails */
export const setCardDetails = (key, value, creditCardDetails) => {
    creditCardDetails[key] = value;

    return creditCardDetails;
};

/** @namespace Tigerone/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;

        return {
            ...state,
            shippingFields
        };
    case LAST_ADDRESS_UPDATED:
        const { address } = action;
        if (address) {
            return {
                ...state,
                address
            };
        }

        return {
            ...state,
            isLoading: true
        };

    case UPDATE_TOTALS_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    case UPDATE_EMAIL:
        const { email } = action;

        return {
            ...state,
            email
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;

        return {
            ...state,
            isEmailAvailable
        };

    case SET_SHIPPING_METHOD:
        const { code } = action;

        return {
            ...state,
            shipping_method_code: code
        };

    case SET_PAYMENT_METHOD: {
        const { payload } = action;

        return {
            ...state,
            payment_method_code: payload
        };
    }

    case SET_SHIPPING_METHODS:
        const { methods } = action;

        return {
            ...state,
            all_shipping_methods: methods
        };

    case SET_CREDIT_CARD_DETAILS:
        const { key, value } = action;
        const { creditCardDetails } = state;

        return {
            ...state,
            creditCardDetails: setCardDetails(key, value, creditCardDetails)
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
