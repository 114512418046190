export const SHIPPING_STEP = 'SHIPPING_STEP';
export const BILLING_STEP = 'BILLING_STEP';
export const DETAILS_STEP = 'DETAILS_STEP';
export const CHECKOUT_STEP = 'CHECKOUT_STEP';

export const CHECKOUT_URL = '/checkout';
export const BILLING_URL = '/checkout/billing';
export const SHIPPING_URL = '/checkout/shipping';
export const SUCCESS_URL = '/checkout/success';

export const PAYMENT_TOTALS = 'PAYMENT_TOTALS';

export const UPDATE_EMAIL_CHECK_FREQUENCY = 1500; // ms

export const ADDRESS_FIELDS = [
    'firstname',
    'lastname',
    'telephone',
    'city',
    'postcode'
];

/** @namespace Tigerone/Route/Checkout/Config/hasAllFieldsInAddress */
export const hasAllFieldsInAddress = (address) => ADDRESS_FIELDS.every((field) => address[field]);
