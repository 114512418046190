import { KeyValueTable as SourceKeyValueTable } from 'SourceComponent/KeyValueTable/KeyValueTable.component';

import './KeyValueTable.style';

/** @namespace Tigerone/Component/KeyValueTable/Component */
export class KeyValueTableComponent extends SourceKeyValueTable {
    renderTableRow(data) {
        const { key } = data;
        const value = this.getValueFromSource(data);
        if (!value) {
            return null;
        }

        return (
            <tr key={ key }>
                <td>{ value }</td>
            </tr>
        );
    }
}

export default KeyValueTableComponent;
