/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';

import {
    ProductConfigurableAttributeDropdownContainer as SourceProductConfigurableAttributeDropdownContainer
} from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.container';
import { AttributeType } from 'Type/ProductList.type';

import ProductConfigurableAttributeDropdown from './ProductConfigurableAttributeDropdown.component';

/** @namespace Tigerone/Component/ProductConfigurableAttributeDropdown/Container */
export class ProductConfigurableAttributeDropdownContainer extends SourceProductConfigurableAttributeDropdownContainer {
    static propTypes = {
        option: AttributeType.isRequired,
        updateConfigurableVariant: PropTypes.func.isRequired,
        getIsConfigurableAttributeAvailable: PropTypes.func.isRequired,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        isUnselected: PropTypes.bool,
        isQuickOrderSearch: PropTypes.func.isRequired,
        saveConfigProduct: PropTypes.func.isRequired,
        parentProduct: PropTypes.objectOf.isRequired
    };

    static defaultProps = {
        isUnselected: false
    };

    containerFunctions = {
        onChange: this.onChange.bind(this)
    };

    onChange(value) {
        const {
            updateConfigurableVariant,
            option: { attribute_code },
            isQuickOrderSearch,
            saveConfigProduct,
            parentProduct
        } = this.props;

        const variant = updateConfigurableVariant(attribute_code, value, true);

        if (isQuickOrderSearch) {
            saveConfigProduct(variant, attribute_code, value, parentProduct);
        }
    }

    containerProps() {
        const { option: { attribute_code, attribute_label }, isUnselected } = this.props;

        return {
            selectValue: this._getSelectValue(),
            selectOptions: this._getSelectOptions(),
            selectName: attribute_code,
            selectLabel: attribute_label,
            isUnselected
        };
    }

    _getSelectOptions() {
        const {
            option: {
                attribute_options,
                attribute_code,
                attribute_values
            },
            getIsConfigurableAttributeAvailable
        } = this.props;

        if (!attribute_options) {
            // eslint-disable-next-line no-console
            console.warn(`Please make sure "${ attribute_code }" is visible on Storefront.`);

            return [];
        }

        const sortedOptions = attribute_values.map((value) => {
            const option = attribute_options[value];
            if (option) {
                const isAvailable = getIsConfigurableAttributeAvailable({
                    attribute_code,
                    attribute_value: value
                });

                return {
                    ...option,
                    id: value,
                    isAvailable
                };
            }

            return null;
        }).filter(Boolean);

        return sortedOptions;
    }

    _getSelectValue() {
        const { option: { attribute_code } } = this.props;
        const { parameters = {} } = this.props;

        return parameters[attribute_code];
    }

    render() {
        return (
            <ProductConfigurableAttributeDropdown
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductConfigurableAttributeDropdownContainer;
