/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import {
    UPDATE_CONFIG as SOURCE_UPDATE_CONFIG,
    UPDATE_CONFIG_DEVICE as SOURCE_UPDATE_CONFIG_DEVICE
} from 'SourceStore/Config/Config.action';
import {
    ConfigReducer as sourceConfigReducer,
    DEFAULT_CATGORY_URL_SUFFIX,
    filterStoreConfig,
    getCheckoutAgreementData,
    getCountryData,
    getIndexedRatings,
    getInitialState as sourceGetInitialState,
    MAX_HEIGHT,
    MAX_WIDTH
} from 'SourceStore/Config/Config.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';
import { filterStoreCountries } from 'Util/StoreCountries';
import { getStoreCodes } from 'Util/Url';

import { SAVE_CURRENT_PAGE } from './Config.action';

export {
    MAX_WIDTH,
    MAX_HEIGHT,
    DEFAULT_CATGORY_URL_SUFFIX,
    filterStoreConfig,
    getIndexedRatings,
    getCountryData,
    getCheckoutAgreementData
};
export const UPDATE_CONFIG = SOURCE_UPDATE_CONFIG;
export const UPDATE_CONFIG_DEVICE = SOURCE_UPDATE_CONFIG_DEVICE;

export const {
    reviewRatings, storeConfig, currencyData, cartDisplayConfig,
    countries_sort_order
} = BrowserDatabase.getItem('config') || {
    reviewRatings: [],
    storeConfig: {},
    currencyData: {},
    currentPage: '',
    cartDisplayConfig: {
        display_tax_in_price: '',
        display_tax_in_subtotal: '',
        display_tax_in_shipping_amount: '',
        include_tax_in_order_total: false,
        display_full_tax_summary: false,
        display_zero_tax_subtotal: false
    },
    countries_sort_order: []
};

/** @namespace Tigerone/Store/Config/Reducer/getCurrencyData */
export const getCurrencyData = (base, state) => {
    if (base) {
        base.available_currencies_data.map((data) => {
            data.symbol ? data.label = data.symbol + data.label : null;
        });
    } else if (state.currencyData) {
        state.currencyData.available_currencies_data.map((data) => {
            data.symbol ? data.label = data.symbol + data.label : null;
        });
    }

    return base || state.currencyData || {};
};

/** @namespace Tigerone/Store/Config/Reducer/getUpdatedCountries */
export const getUpdatedCountries = (base, state) => (base || state.countries_sort_order || {});

/** @namespace Tigerone/Store/Config/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    category_url_suffix: null,
    allCountries: [],
    countries_sort_order,
    updatedCountries: null
});

/** @namespace Tigerone/Store/Config/Reducer/ConfigReducer */
export const ConfigReducer = (
    state = getInitialState(),
    action
) => {
    if (action.type === SAVE_CURRENT_PAGE) {
        const { pathname } = action;

        return {
            ...sourceConfigReducer(state, action),
            currentPage: pathname
        };
    }

    if (action.type === UPDATE_CONFIG) {
        const { config: { countries, countries_sort_order } = {} } = action;
        return {
            ...sourceConfigReducer(state, action),
            allCountries: getCountryData(countries, state),
            countries: filterStoreCountries(countries, getStoreCodes()),
            updatedCountries: getUpdatedCountries(countries_sort_order, state)
        };
    }

    return sourceConfigReducer(state, action);
};

export default ConfigReducer;
