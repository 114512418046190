export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const CLEAR_ALL = 'CLEAR_ALL';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const CSV_FILE = 'CSV_FILE';
export const UPDATE_SEARCH_BAR = 'UPDATE_SEARCH_BAR';
export const UPDATE_SEARCH_LOAD_STATUS = 'UPDATE_SEARCH_LOAD_STATUS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const SAVE_CHILD_PRODUCT = 'SAVE_CHILD_PRODUCT';
export const RESET_CONFIG_CHILD = 'RESET_CONFIG_CHILD';
export const CSV_UPDATE_QTY = 'CSV_UPDATE_QTY';
export const CLEAR_ALL_SEARCH_LIST = 'CLEAR_ALL_SEARCH_LIST';
export const CLEAR_ALL_CSV_LIST = 'CLEAR_ALL_CSV_LIST';
export const RESET_CSV_PRODUCTS = 'RESET_CSV_PRODUCTS';
export const REMOVE_SELECTED_VARIANT = 'REMOVE_SELECTED_VARIANT';

/** @namespace Tigerone/Store/QuickOrder/Action/updateSearchBar */
export const updateSearchBar = (result) => ({
    type: UPDATE_SEARCH_BAR,
    result
});

/** @namespace Tigerone/Store/QuickOrder/Action/updateLoadStatus */
export const updateLoadStatus = (status) => ({
    type: UPDATE_SEARCH_LOAD_STATUS,
    isLoading: status
});

/** @namespace Tigerone/Store/QuickOrder/Action/clearSearchResults */
export const clearSearchResults = () => ({
    type: CLEAR_SEARCH_RESULTS
});

/** @namespace Tigerone/Store/QuickOrder/Action/addProducts */
export const addProducts = (sku, qty, product, selectedPackSize, config_product, attributeCode, attributeValue) => ({
    type: ADD_PRODUCTS,
    sku,
    qty,
    product,
    selectedPackSize,
    config_product,
    attributeCode,
    attributeValue
});

/** @namespace Tigerone/Store/QuickOrder/Action/clearAllSearchList */
export const clearAllSearchList = () => ({
    type: CLEAR_ALL_SEARCH_LIST
});

/** @namespace Tigerone/Store/QuickOrder/Action/clearAllCsvList */
export const clearAllCsvList = () => ({
    type: CLEAR_ALL_CSV_LIST
});

/** @namespace Tigerone/Store/QuickOrder/Action/resetCSV */
export const resetCSV = () => ({
    type: RESET_CSV_PRODUCTS
});

/** @namespace Tigerone/Store/QuickOrder/Action/removeItem */
export const removeItem = (removeProduct, listType) => ({
    type: REMOVE_ITEM,
    removeProduct,
    listType
});

/** @namespace Tigerone/Store/QuickOrder/Action/csvAddQuantity */
export const csvAddQuantity = (products, qtyObj) => ({
    type: CSV_FILE,
    products,
    qtyObj
});

/** @namespace Tigerone/Store/QuickOrder/Action/saveConfigChildProduct */
export const saveConfigChildProduct = (data) => ({
    type: SAVE_CHILD_PRODUCT,
    data
});

/** @namespace Tigerone/Store/QuickOrder/Action/resetConfigAfterSelection */
export const resetConfigAfterSelection = () => ({
    type: RESET_CONFIG_CHILD
});

/** @namespace Tigerone/Store/QuickOrder/Action/removeSelectedVariant */
export const removeSelectedVariant = (data) => ({
    type: REMOVE_SELECTED_VARIANT,
    data
});

/** @namespace Tigerone/Store/QuickOrder/Action/csvUpdateQuantity */
export const csvUpdateQuantity = (csvSKU, updatedQty) => ({
    type: CSV_UPDATE_QTY,
    csvSKU,
    updatedQty
});
