/* eslint-disable no-undef */
import CheckoutAchForm from 'Component/CheckoutAchForm';
import CheckoutCreditCardForm from 'Component/CheckoutCreditCardForm';

export const PAYTRIOT_PAYMENT_CODE = 'paytriot_gateway';
export const NMI_PAYMENT_CODE = 'rootways_nmi_option';

export const ACH_PAYMENT_CODE = 'accept_blue_ach';
/**
 *
 * @param {*} args argument of this plugin method
 * @param {*} callback original method
 * @param {*} instance [Optional] - Use this to access the props ex: instance.props
 * @returns
 */
const newRender = (args, callback) => {
    const { method: { code } = {}, isValidCardDetails } = args[0] || [];
    switch (code) {
    case PAYTRIOT_PAYMENT_CODE:
        return (
                <CheckoutCreditCardForm
                  isValidCardDetails={ isValidCardDetails }
                  code={ code }
                />
        );

    case NMI_PAYMENT_CODE:
        return (
                <CheckoutCreditCardForm
                  isValidCardDetails={ isValidCardDetails }
                  code={ code }
                />
        );
    case ACH_PAYMENT_CODE:
        return (
                <CheckoutAchForm />
        );

    default:
        return callback(...args);
    }
};

export default {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            renderPaymentField: newRender
        }
    }
};
