/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';

import {
    Image as SourceImage
} from 'SourceComponent/Image/Image.component';
import { MixType, RefType } from 'Type/Common.type';
import { isCrawler } from 'Util/Browser';
import { noopFn } from 'Util/Common';

import { IMAGE_NOT_FOUND } from './Image.config';

import './Image.override.style.scss';
/** @namespace Tigerone/Component/Image/Component */
export class ImageComponent extends SourceImage {
    static propTypes = {
        isPlaceholder: PropTypes.bool,
        src: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool
        ]),
        style: PropTypes.objectOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool
        ])),
        width: PropTypes.string,
        height: PropTypes.string,
        alt: PropTypes.string,
        ratio: PropTypes.oneOf([
            '4x3',
            '16x9',
            'square',
            'custom'
        ]),
        mix: MixType,
        className: PropTypes.string,
        imageRef: RefType,
        title: PropTypes.string,
        isPlain: PropTypes.bool,
        showIsLoading: PropTypes.bool,
        onImageLoad: PropTypes.func,
        onImageLoadFail: PropTypes.func
    };

    static defaultProps = {
        src: '',
        alt: '',
        ratio: 'square',
        mix: {},
        height: '',
        width: '',
        isPlaceholder: false,
        style: {},
        title: null,
        className: '',
        imageRef: noopFn,
        isPlain: false,
        showIsLoading: false,
        onImageLoad: noopFn,
        onImageLoadFail: noopFn
    };

    onError() {
        const { onImageLoadFail } = this.props;
        onImageLoadFail();
        this.setState({ imageStatus: IMAGE_NOT_FOUND });
    }

    renderDefaultImage() {
        const {
            src,
            alt,
            title,
            wrapperSize,
            ratio,
            mix,
            imageRef,
            className
        } = this.props;

        return (
            <div
              block="Image"
              elem="Default Image"
              ref={ imageRef }
              mods={ {
                  ratio
              } }
              mix={ mix }
              style={ wrapperSize }
              className={ className }
            >
                <img
                  style={ wrapperSize }
                  src={ src || '' }
                  alt={ alt }
                  title={ title }
                />
            </div>
        );
    }

    render() {
        const {
            ratio,
            mix,
            isPlaceholder,
            wrapperSize,
            src,
            imageRef,
            className,
            isPlain
        } = this.props;

        const { imageStatus } = this.state;

        if (isCrawler()) {
            return this.renderDefaultImage();
        }

        // render image as is: without additional container and additional styles
        if (isPlain) {
            return this.renderImage();
        }

        return (
            <div
              block="Image"
              ref={ imageRef }
              mods={ {
                  ratio,
                  imageStatus: imageStatus.toLowerCase(),
                  isPlaceholder,
                  hasSrc: !!src
              } }
              mix={ mix }
              style={ wrapperSize }
                // eslint-disable-next-line react/forbid-dom-props
              className={ className }
            >
                { this.renderImage() }
                { this.renderLoader() }
            </div>
        );
    }
}

export default ImageComponent;
