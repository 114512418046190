/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import RadioButton from 'Component/RadioButtonIcon';
import { CheckoutAddressTable as SourceCheckoutAddressTable } from 'SourceComponent/CheckoutAddressTable/CheckoutAddressTable.component';

/** @namespace Tigerone/Component/CheckoutAddressTable/Component */
export class CheckoutAddressTableComponent extends SourceCheckoutAddressTable {
    renderHeading() {
        const { title, isSelected } = this.props;

        if (!title) {
            return null;
        }

        return (
            <tr>
                <th
                  block="KeyValueTable"
                  elem="Heading"
                  colSpan={ 2 }
                >
                    <RadioButton isActive={ isSelected } />
                    { this.renderCheckbox(isSelected) }
                </th>
            </tr>
        );
    }

    renderTable() {
        const { title, isSelected } = this.props;

        if (!title) {
            return null;
        }

        return (
            <button
              block="CheckoutAddressTable"
              elem="Button"
              type="button"
              mods={ { isSelected } }
              onClick={ this.onAddressClick }
            >
                <div block="KeyValueTable" elem="Wrapper">
                <table block="KeyValueTable">
                    <tbody>
                        { this.dataPairArray.map(this.renderTableRow.bind(this)) }
                    </tbody>
                    { this.renderHeading() }
                </table>
                </div>
            </button>
        );
    }

    renderCheckbox(isSelected) {
        return (
            <div block="CheckoutAddressTable" elem="CheckRadioBlock" mods={ { isSelected } }>
            <svg
              x="0px"
              y="0px"
              viewBox="0 0 17.837 17.837"
              xmlSpace="preserve"
              block="CheckoutAddressTable"
              elem="CheckRadio"
              mods={ { isSelected } }
            >
                <g>
                    <path
                      d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27
                        c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0
                        L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                    />
                </g>
            </svg>
            </div>
        );
    }
}
export default CheckoutAddressTableComponent;
