/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
// import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import { PRODUCT_TYPE } from 'SourceComponent/Product/Product.config';
import { getIndexedProducts } from 'SourceUtil/Product';
import {
    ADD_PRODUCTS, CLEAR_ALL_CSV_LIST, CLEAR_ALL_SEARCH_LIST, CLEAR_SEARCH_RESULTS,
    CSV_FILE, CSV_UPDATE_QTY, REMOVE_ITEM, REMOVE_SELECTED_VARIANT,
    RESET_CONFIG_CHILD, RESET_CSV_PRODUCTS,
    SAVE_CHILD_PRODUCT,
    UPDATE_SEARCH_BAR, UPDATE_SEARCH_LOAD_STATUS
} from 'Store/QuickOrder/QuickOrder.action';

export const QUICK_ORDER = 'quick_order';

/** @namespace Tigerone/Store/QuickOrder/Reducer/getInitialState */
export const getInitialState = () => ({
    cartList: [],
    csvCartList: [],
    selected_child_product: null,
    qtyObj: null,
    updatedQtyObj: {},
    isLoading: false
});

/** @namespace Tigerone/Store/QuickOrder/Reducer/updateProductQuantityAdded */
export const updateProductQuantityAdded = (cartList, qty, sku, product, selectedPackSize, config_product, attributeCode, attributeValue) => {
    const { type_id } = product;
    if (!cartList) {
        cartList = [];
    }

    if (type_id === PRODUCT_TYPE.simple) {
        if (!cartList.length) {
            cartList = [];
            product.qty = qty;
            cartList.push(product);
        } else {
            const value = cartList.find((item) => item.sku === sku);
            if (value === undefined) {
                product.qty = qty;
                cartList.push(product);
            } else {
                value.qty = qty;
            }
        }
    } else if (type_id === PRODUCT_TYPE.configurable) {
        if (!cartList.length) {
            cartList = [];
            product.qty = qty;
            cartList.push(product);
            cartList[cartList.length - 1].packSize = selectedPackSize;
            cartList[cartList.length - 1].selectedChildProduct = config_product;
            cartList[cartList.length - 1].attributeCode = attributeCode;
            cartList[cartList.length - 1].attributeValue = attributeValue;
        } else {
            const value = cartList.find((item) => item.sku === sku);
            if (value === undefined) {
                product.qty = qty;
                cartList.push(product);
                cartList[cartList.length - 1].packSize = selectedPackSize;
                cartList[cartList.length - 1].selectedChildProduct = config_product;
                cartList[cartList.length - 1].attributeCode = attributeCode;
                cartList[cartList.length - 1].attributeValue = attributeValue;
            } else {
                const size = cartList.find((item) => item.packSize === selectedPackSize);
                if (size === undefined) {
                    product.qty = qty;
                    cartList.push(product);
                    cartList[cartList.length - 1].packSize = selectedPackSize;
                    cartList[cartList.length - 1].selectedChildProduct = config_product;
                    cartList[cartList.length - 1].attributeCode = attributeCode;
                    cartList[cartList.length - 1].attributeValue = attributeValue;
                } else {
                    size.qty = qty;
                }
            }
        }
    }

    // BrowserDatabase.setItem(cartList, QUICK_ORDER);
    return cartList;
};

/** @namespace Tigerone/Store/QuickOrder/Reducer/removeItem */
export const removeItem = (cartList, removeProduct) => {
    const products = cartList.filter((item) => item.sku !== removeProduct.sku);
    cartList = products;

    // BrowserDatabase.setItem(cartList, QUICK_ORDER);
    return cartList;
};

/** @namespace Tigerone/Store/QuickOrder/Reducer/getUpdatedCSVQuantity */
export const getUpdatedCSVQuantity = (sku, updatedQty, newQtyObj) => {
    newQtyObj[sku] = updatedQty;

    return newQtyObj;
};

/** @namespace Tigerone/Store/QuickOrder/Reducer/csvFileAddProducts */
export const csvFileAddProducts = (products, qtyObj, csvCartList) => {
    const { items } = products;

    items.map((item) => {
        Object.keys(qtyObj).map((data) => {
            if (item.sku === data) {
                item.qty = qtyObj[data];
            }
        });
    });

    if (!csvCartList) {
        csvCartList = [];
    }

    if (!csvCartList.length) {
        items.map((item) => {
            csvCartList.push(item);
        });
    } else {
        items.map((item) => {
            const filterList = csvCartList.filter((product) => product.sku === item.sku);
            if (!filterList.length) {
                csvCartList.push(item);
            } else {
                filterList[0].qty += item.qty;
            }
        });
    }

    // BrowserDatabase.setItem(cartList, QUICK_ORDER);
    // const { cartList } = getInitialState();
    return csvCartList;
};

/** @namespace Tigerone/Store/QuickOrder/Reducer/QuickOrderReducer */
export const QuickOrderReducer = (state = getInitialState(), action) => {
    const {
        sku, qty, product, data, selectedPackSize, config_product, removeProduct
    } = action;
    const { cartList, csvCartList } = state;

    switch (action.type) {
    case ADD_PRODUCTS:
        return {
            ...state,
            cartList: updateProductQuantityAdded(cartList, qty, sku, product, selectedPackSize, config_product)
        };

    case CLEAR_ALL_SEARCH_LIST:
        return {
            ...state,
            cartList: []
        };

    case CLEAR_ALL_CSV_LIST:
        return {
            ...state,
            csvCartList: []
        };

    case REMOVE_SELECTED_VARIANT:
        const { parent } = data;

        return {
            ...state,
            selected_child_product: {
                ...state.selected_child_product,
                [parent]: null
            }
        };

    case RESET_CONFIG_CHILD:
        return {
            ...state,
            selected_child_product: null
        };

    case RESET_CSV_PRODUCTS:
        return {
            ...state,
            qtyObj: null,
            selected_child_product: null,
            updatedQtyObj: {}
        };

    case REMOVE_ITEM:
        const { listType = 'SEARCH_LIST' } = action;

        switch (listType) {
        case 'CSV_LIST':
            return {
                ...state,
                csvCartList: removeItem(csvCartList, removeProduct)
            };
        default:
            return {
                ...state,
                cartList: removeItem(cartList, removeProduct)
            };
        }

    case CSV_FILE:
        const {
            products: {
                items = []
            }, qtyObj
        } = action;

        return {
            ...state,
            csvCartList: getIndexedProducts(items),
            qtyObj
        };

    case CSV_UPDATE_QTY:
        const {
            csvSKU,
            updatedQty
        } = action;

        const { updatedQtyObj: newQtyObj } = state;

        return {
            ...state,
            updatedQtyObj: getUpdatedCSVQuantity(csvSKU, updatedQty, newQtyObj)
        };

    case UPDATE_SEARCH_BAR:
        const { result: { products: { items: initialItems } } } = action;

        return {
            ...state,
            productsInSearch: getIndexedProducts(initialItems)
        };

    case UPDATE_SEARCH_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    case CLEAR_SEARCH_RESULTS:
        return {
            ...state,
            productsInSearch: getInitialState().productsInSearch
        };

    case SAVE_CHILD_PRODUCT:
        const {
            parentSku, variant, attributeCode, attributeValue, selectedPackSize: configSelected
        } = data;

        return {
            ...state,
            selected_child_product: {
                ...state.selected_child_product,
                [parentSku]: {
                    ...variant, attributeCode, attributeValue, selectedPackSize: configSelected
                }
            }
        };

    default:
        return state;
    }
};

export default QuickOrderReducer;
