/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ExpandableContent as ParentExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';
/** @namespace Tigerone/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends ParentExpandableContent {
    static defaultProps = {
        heading: '',
        isContentExpanded: false,
        onClick: null,
        children: [],
        isArrow: false,
        mods: {},
        isSelected: false
    };

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow } = this.props;

        // if (!isMobile) {
        //     return null;
        // }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const { heading, mix, isSelected } = this.props;

        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="ExpandableContent"
              elem={ isSelected ? 'Button Selected' : 'Button' }
              mods={ { isContentExpanded } }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              onClick={ this.toggleExpand }
            >
                <div
                  block="ExpandableContent"
                  elem="Heading"
                  mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                >
                    { typeof heading === 'string' ? (
                        <TextPlaceholder content={ heading } length="medium" />
                    ) : (
                        heading
                    ) }
                </div>
                { this.renderButtonIcon() }
            </div>
        );
    }
}

export default ExpandableContentComponent;
