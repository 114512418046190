export const UPDATE_INFO_LOAD_STATUS = 'UPDATE_INFO_LOAD_STATUS';

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Tigerone/Store/Company/Action/updateInfoLoadStatus */
export const updateInfoLoadStatus = (status) => ({
    type: UPDATE_INFO_LOAD_STATUS,
    isLoading: status
});
