import { UPDATE_INFO_LOAD_STATUS } from './Company.action';

/** @namespace Tigerone/Store/Company/Reducer/getInitialState */
export const getInitialState = () => ({
    isLoading: false
});

/** @namespace Tigerone/Store/Company/Reducer/CompanyReducer */
export const CompanyReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_INFO_LOAD_STATUS: {
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };
    }

    default:
        return state;
    }
};

export default CompanyReducer;
