/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import CheckoutPayment from 'Component/CheckoutPayment';
import {
    CheckoutPayments as SourceCheckoutPayments
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.component';

import './CheckoutPayments.override.style';

/** @namespace Tigerone/Component/CheckoutPayments/Component */
export class CheckoutPaymentsComponent extends SourceCheckoutPayments {
    renderPaymentInstructions() {
        const { selectedPaymentCode, paymentMethods } = this.props;

        const method = paymentMethods.find((data) => data.code === selectedPaymentCode);

        if (method && method.payment_instructions) {
            return (
                <div
                  block="CheckoutPayments"
                  elem="paymentInfo"
                >
                    <h4>{ __('Payment Instruction: ') }</h4>
                    <p>{ method.payment_instructions }</p>
                </div>
            );
        }
    }

    renderPaymentField(method) {
        return null;
    }

    renderPayment(method) {
        const {
            selectPaymentMethod,
            selectedPaymentCode,
            isMobile
        } = this.props;

        const { code } = method;
        const isSelected = selectedPaymentCode === code;

        return (
            <CheckoutPayment
              key={ code }
              isSelected={ isSelected }
              method={ method }
              onClick={ selectPaymentMethod }
              isMobile={ isMobile }
            />
        );
    }

    renderContent() {
        const { hasError } = this.state;
        const {
            isMobile, selectedPaymentCode,
            paymentMethods, isValidCardDetails
        } = this.props;

        const method = paymentMethods.find((data) => data.code === selectedPaymentCode);

        const options = {
            method,
            isValidCardDetails
        };

        if (hasError) {
            return (
                <p>{ __('The error occurred during initializing payment methods. Please try again later!') }</p>
            );
        }

        return (
            <>
                { this.renderHeading() }
                { this.renderDescText() }
                <ul block="CheckoutPayments" elem="Methods">
                    { this.renderPayments() }
                </ul>
                { selectedPaymentCode ? this.renderPaymentField(options) : null }
                { !isMobile ? this.renderPaymentInstructions() : null }
                { this.renderSelectedPayment() }
            </>
        );
    }

    renderHeading() {
        return (
            <h2 block="Checkout" elem="Heading">
                { __('Payment Method') }
            </h2>
        );
    }

    renderDescText() {
        return (
            <p block="Checkout" elem="DescText">
                { __('Select Payment method:') }
            </p>
        );
    }
}
export default CheckoutPaymentsComponent;
