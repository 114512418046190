import { Field } from 'SourceUtil/Query';

/**
 * Ordered Summary Query
 * @class OrderedSummaryQuery
 * @namespace Tigerone/Query/MyAccountCreateCompany/Query */
export class MyAccountCreateCompanyQuery {
    getCreateCompanyMutation(options) {
        return new Field('createCompany')
            .addArgument('input', 'CompanyCreateInput!', options)
            .addField(this.getResponseId());
    }

    getResponseId() {
        return new Field('company')
            .addFieldList([
                'id'
            ]);
    }
}

export default new MyAccountCreateCompanyQuery();
