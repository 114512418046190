/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { AddToCart as sourceAddToCart } from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style';

/**
 * Button for adding product to Cart
 * @class AddToCart
 * @namespace Tigerone/Component/AddToCart/Component */
export class AddToCartComponent extends sourceAddToCart {
    render() {
        const {
            mix,
            addProductToCart,
            layout,
            isDisabled,
            isAdding
        } = this.props;

        return (
            <button
              onClick={ addProductToCart }
              block="Button AddToCart"
              mix={ mix }
              mods={ { layout } }
              disabled={ isDisabled || isAdding }
            >
                { this.renderCartIcon() }
                <span>{ isAdding ? __('Adding...') : __('Add To Basket') }</span>
            </button>
        );
    }
}

export default AddToCartComponent;
