/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import {
    isSignedIn
} from 'Util/Auth';
import { getCountryAndLanguageCode } from 'Util/Url';

export const INTERVAL = 3000;

/** @namespace Tigerone/Util/Insider/Index/fireInsiderEvent */
export const fireInsiderEvent = (eventType, customerData = {}, isPersistent = true) => {
    if (!customerData) {
        window.insider_object = {};
        return;
    }

    const {
        id, email, is_subscribed, firstname, lastname
    } = customerData;

    const { countryCode, languageCode } = getCountryAndLanguageCode();

    const eventData = {
        ...(isSignedIn() ? { uuid: `${id}` } : null),
        gdpr_optin: true,
        name: firstname,
        surname: lastname,
        email,
        email_optin: is_subscribed,
        language: `${languageCode}-${countryCode !== 'uk' ? countryCode : 'gb'}`,
        custom: {
            outdoor_grow_guide: '',
            new_release_sign_up_email: '',
            new_release_sign_up: '',
            user_verified: isSignedIn()
        }
    };

    if (window.Insider && typeof window.Insider === 'object') {
        window.insider_object = { [eventType]: eventData };
    } else if (isPersistent) {
        setTimeout(() => {
            fireInsiderEvent(eventType, customerData, false);
        }, INTERVAL); // try again in 3 seconds
    }
};

/** @namespace Tigerone/Util/Insider/Index/fireInsiderPageEvent */
export const fireInsiderPageEvent = (pageType, cartTotals) => {
    const cart = {
        currency: cartTotals?.quote_currency_code || '',
        total: cartTotals?.grand_total || 0,
        shipping_cost: cartTotals?.shipping_amount || 0,
        line_items: []
    };
    const cartItems = cartTotals?.items;
    console.log(cartItems, 'Insider cart items');
    if (cartItems) {
        cartItems.map((item) => {
            const productItems = item?.product;
            let productVariantId = 0;
            let productVariantName = '';
            let unit_sale_price_total = 0;
            let unitPrice = 0;
            let currencyValue = 0;
            if (productItems) {
                const productVariant = productItems?.variants;
                if (productVariant.length > 0) {
                    productVariant.map((item) => {
                        productVariantId = item?.id;
                        productVariantName = item?.name;
                    });
                }

                if (productItems?.type_id === 'simple') {
                    unitPrice = productItems?.price_range?.maximum_price?.regular_price?.value;
                    unit_sale_price_total = ((productItems?.price_range?.maximum_price?.regular_price?.value) - (item?.discount_amount));
                    currencyValue = productItems?.price_range?.maximum_price?.regular_price?.currency;
                } else if (productItems?.type_id === 'bundle') {
                    unitPrice = productItems?.price_range?.maximum_price?.regular_price?.value;
                    unit_sale_price_total = ((productItems?.price_range?.maximum_price?.regular_price?.value) - (item?.discount_amount));
                    currencyValue = productItems?.price_range?.maximum_price?.regular_price?.currency;
                } else if (productItems?.type_id === 'configurable') {
                    unitPrice = productItems?.variants[0]?.price_range?.maximum_price?.regular_price?.value;
                    unit_sale_price_total = ((productItems?.variants[0]?.price_range?.maximum_price?.final_price?.value) - (item?.discount_amount));
                    currencyValue = productItems?.variants[0]?.price_range?.maximum_price?.regular_price?.currency;
                } else {
                    unitPrice = productItems?.price_range?.maximum_price?.regular_price?.value;
                    unit_sale_price_total = ((productItems?.price_range?.maximum_price?.regular_price?.value) - (item?.discount_amount));
                    currencyValue = productItems?.price_range?.maximum_price?.regular_price?.currency;
                }

                cart.line_items.push({
                    product: {
                        id: productVariantId || productItems?.id,
                        name: productVariantName || productItems?.name || '',
                        product_image_url: productItems?.thumbnail?.url || '',
                        currency: currencyValue || '',
                        unit_sale_price: unit_sale_price_total || 0,
                        unit_price: unitPrice || 0,
                        url: window?.location?.origin + productItems?.url || '',
                        subtotal: (item?.row_total - (item?.discount_amount)) || 0,
                        quantity: item?.qty
                    }
                });
            }
        });
    }
    if (window.Insider && typeof window.Insider === 'object') {
        window.insider_object = {
            page: {
                type: pageType
            },
            basket: { ...cart }
        };
    }
};
