/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/sort-comp */
/* eslint-disable no-debugger */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { EventsType, FieldAttrType } from 'Type/Field.type';

import FieldFile from './FieldFile.component';

/** @namespace Tigerone/Component/FieldFile/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Tigerone/Component/FieldFile/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/**
 * Field File
 * @class FieldFileContainer
 * @namespace Tigerone/Component/FieldFile/Container */
export class FieldFileContainer extends PureComponent {
    static propTypes = {
        // Field attributes
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        setRef: PropTypes.func.isRequired,
        fileEvents: EventsType.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    containerFunctions = {
        setRef: this.setRef.bind(this)
    };

    state = {
        isLoading: false,
        fileName: '',
        value: ''
    };

    fieldRef = createRef();

    setRef(elem) {
        const { setRef } = this.props;
        setRef(elem);
        if (elem && this.fieldRef !== elem) {
            this.fieldRef = elem;
        }
    }

    onChange() {
        const {
            events: { onChange } = {},
            attr: {
                getCsvFileData,
                isQuickOrderUpload,
                checkFileValidity
            },
            showNotification
        } = this.props;

        if (this.fieldRef) {
            const { files } = this.fieldRef;
            this.setState({ isLoading: true });
            const { name } = files[0] || {};
            if (!name) {
                this.setState({
                    fileName: '',
                    isLoading: false
                });

                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                this.setState({
                    fileName: name,
                    isLoading: false,
                    value: reader.result
                });
                this.fieldRef.fileData = JSON.stringify({
                    file_data: reader.result,
                    file_name: name
                });

                if (isQuickOrderUpload) {
                    const allowedExtensions = /(\.csv)$/i;
                    if (!allowedExtensions.exec(name)) {
                        showNotification('error', __('Invalid file type , Allowed file type csv'));
                        this.setState({
                            fileName: ''
                        });

                        return false;
                    }
                    getCsvFileData(reader.result);
                }

                if (typeof onChange === 'function') {
                    if (checkFileValidity) {
                        if (isQuickOrderUpload && (files[0].size / (1024 * 1024)) > 2) {
                            showNotification('error', __('File is too big, please select a file less than 2MB'));
                            this.setState({
                                fileName: ''
                            });

                            return false;
                        }

                        if ((files[0].size) / (1024 * 1024) > 5) {
                            showNotification('error', __('File is too big, please select a file less than 5MB'));
                            this.setState({
                                fileName: ''
                            });

                            return false;
                        }

                        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
                        if (!allowedExtensions.exec(name)) {
                            showNotification('error', __('Invalid file type , Allowed file type png , jpeg , jpg'));
                            this.setState({
                                fileName: ''
                            });

                            return false;
                        }

                        onChange(files, this.fieldRef.id);
                    } else {
                        onChange(files, this.fieldRef.id);
                    }
                }
            };
            reader.onerror = () => {
                // TODO: Add showNotification('error', __('Failed to upload file'))
                this.setState({ fileName: '', isLoading: false });

                if (typeof onChange === 'function') {
                    onChange(files);
                }
            };
            isQuickOrderUpload ? reader.readAsText(files[0]) : reader.readAsDataURL(files[0]);
        }
    }

    containerProps() {
        const {
            events,
            fileEvents,
            attr: {
                autoComplete,
                autocomplete,
                ...attr
            } = {},
            setRef,
            // eslint-disable-next-line react/prop-types
            renderchangecontent
        } = this.props;
        const { fileName, isLoading, value } = this.state;

        return {
            attr: {
                ...attr,
                autoComplete: autoComplete || autocomplete
            },
            setRef,
            renderchangecontent,
            events: {
                ...events,
                onChange: this.onChange.bind(this)
            },
            fileEvents: {
                ...fileEvents
            },
            fileName,
            isLoading,
            value
        };
    }

    render() {
        return (
            <FieldFile
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldFileContainer);
