/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Select from 'react-select';

import CloseIcon from 'Component/CloseIcon';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import FooterPopup from 'Component/FooterPopup';
import Form from 'Component/Form';
import Link from 'Component/Link';
import { CountriesType } from 'Type/Config.type';
import { searchDropDownArray } from 'Util/SearchDropDown';

import './StoreSwitcherV2.style';

/** @namespace Tigerone/Component/StoreSwitcherV2/Component */
export class StoreSwitcherV2Component extends PureComponent {
    static propTypes = {
        country: PropTypes.string.isRequired,
        handlePopup: PropTypes.func.isRequired,
        showCountrySelectionPopup: PropTypes.func.isRequired,
        showCountrySelection: PropTypes.bool.isRequired,
        countries: CountriesType.isRequired,
        onSuccess: PropTypes.func.isRequired,
        onClickCheckedBox: PropTypes.func.isRequired,
        countryChecked: PropTypes.bool.isRequired,
        onClickAge18Btn: PropTypes.func.isRequired,
        isCustomer18Plus: PropTypes.bool.isRequired,
        handleCountryChange: PropTypes.func.isRequired,
        is_US_Country: PropTypes.func.isRequired,
        updatedCountries: CountriesType.isRequired,
        selectedCountryId: PropTypes.string.isRequired,
        isCheckout: PropTypes.bool.isRequired
    };

    renderButton() {
        const { country, isCheckout } = this.props;

        if (isCheckout) {
            return (
                <button block="Button chooseCountry" type="button">{ __('Choose your country') } </button>
            );
        }

        return (
            <div block="StoreSwitcherV2" elem="Button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="24"
                  viewBox="0 0 17 24"
                  aria-hidden="true"
                  className="Icon__StyledSVG-sc-lm07h6-0 gVioTP RegionSelectorstyles__PointerIcon-sc-14dxhlg-2 izbKLk"
                >
                <path
                  d="M8.303.89c2.065 0 3.92.818 5.258 2.156a7.391 7.391 0 012.156 5.257c0 4.906-5.485 11.795-7.414 14.066C6.373 20.097.89 13.21.89 8.303c0-2.065.818-3.92 2.156-5.257A7.391 7.391 0 018.303.89zm0 3.558a3.84 3.84 0 00-2.734 1.12 3.84 3.84 0 00-1.12 2.735 3.84 3.84 0 001.12 2.735 3.84 3.84 0 002.734 1.12 3.84 3.84 0 002.735-1.12 3.84 3.84 0 001.12-2.735 3.84 3.84 0 00-1.12-2.734 3.84 3.84 0 00-2.735-1.12z"
                  stroke="currentColor"
                  strokeWidth="1.779"
                  fill="none"
                />
                </svg>
                <span>
                    { country }
                </span>
            </div>
        );
    }

    renderNotePoints() {
        return (
            <div
              block="SelectCountry"
              elem="notePoints"
            >
                <p>
                    { __('By entering this site, you agree to our') } <Link to="/terms-and-conditions">{ __('Terms of use') }</Link> { __('and') }
                    { __('acknowledge that you have read and understood our') } <Link to="/cookies-policy">{ __('Cookie Policy') }</Link> { __('and') } <Link to="/privacy-policy">{ __('Privacy Policy') }</Link>
                </p>
            </div>
        );
    }

    renderCheckBox() {
        const { onClickCheckedBox, countryChecked } = this.props;

        return (
            <div block="StoreSwitcherV2" elem="checkBoxLink">
                <Field
                  type={ FIELD_TYPE.checkbox }
                  label={ (<p>{ __('I accept') }</p>) }
                  attr={ {
                      id: 'confirm_country',
                      name: 'confirm_country',
                      checked: countryChecked
                  } }
                  events={ {
                      onClick: onClickCheckedBox
                  } }
                  addRequiredTag
                  mix={ { block: 'SelectCountry', elem: 'CountryCheckBox' } }
                />
                <div block="StoreSwitcherV2" elem="checkBoxLinks">
                    <Link to="/cookies-policy">{ __('cookie policy') }</Link> { __('and') } <Link to="/privacy-policy">{ __('privacy policy') }</Link>
                </div>
            </div>
        );
    }

    renderAgreeButton() {
        const { countryChecked, isCustomer18Plus, selectedCountryId } = this.props;

        return (
            <button
              block="Button"
              disabled={ !countryChecked || isCustomer18Plus || !selectedCountryId }
            >
                { __('Agree & Enter') }
            </button>
        );
    }

    renderUS_AgeSelection() {
        const { onClickAge18Btn, isCustomer18Plus } = this.props;

        return (
            <div block="SelectCountry" elem={ !isCustomer18Plus ? 'usAgeSelection' : 'usAgeSelection error' }>
                { !isCustomer18Plus
                    ? <h4>{ __('Yes - I am 21+ years old:') }</h4>
                    : <h4>{ __('Sorry, you`re not old enough') }</h4> }
                <p>
                    { __('You must be 21+ years old and over to purchase from our website. Please see') } <Link to="/terms-and-conditions">{ __('Terms & Conditions.') }</Link>
                </p>
                { !isCustomer18Plus
                    ? (
                        <div
                          className="not18Btn"
                          onClick={ onClickAge18Btn }
                        >
                            { __('No, I`m not 21+') }
                        </div>
                    )
                    : (
                        <div
                          className="not18Btn"
                          onClick={ onClickAge18Btn }
                        >
                            { __('Yes, I`m 21+') }
                        </div>
                    ) }
            </div>
        );
    }

    renderAgeSelection() {
        const { onClickAge18Btn, isCustomer18Plus } = this.props;

        return (
            <div block="SelectCountry" elem={ !isCustomer18Plus ? 'ageSelection' : 'ageSelection error' }>
                { !isCustomer18Plus
                    ? <h4>{ __('Yes - I am 18+ years old:') }</h4>
                    : <h4>{ __('Sorry, you`re not old enough') }</h4> }
                <p>
                    { __('You must be 18+ years old and over to purchase from our website. Please see') } <Link to="/terms-and-conditions">{ __('Terms & Conditions.') }</Link>
                </p>
                { !isCustomer18Plus
                    ? (
                        <div
                          className="not18Btn"
                          onClick={ onClickAge18Btn }
                        >
                            { __('No, I`m not 18+') }
                        </div>
                    )
                    : (
                        <div
                          className="not18Btn"
                          onClick={ onClickAge18Btn }
                        >
                            { __('Yes, I`m 18+') }
                        </div>
                    ) }
            </div>
        );
    }

    renderCountryListDropDown() {
        const {
            countries,
            updatedCountries,
            handleCountryChange
        } = this.props;

        const newArr = searchDropDownArray(updatedCountries?.length ? updatedCountries : countries);

        return (
            <div block="SelectCountry" elem="SearchDropDown">
                <Select
                  options={ newArr }
                  placeholder={ __('Select country...') }
                  onChange={ handleCountryChange }
                />
            </div>
        );
    }

    renderContent() {
        const { onSuccess, is_US_Country } = this.props;

        return (
            <>
                <div block="SelectCountry" elem="HeaderContent">
                    <h4>{ __('Select Your Location:') } </h4>
                    <p>
                        { __('Please select your delivery country to shop the products and offers available to you.') }
                    </p>
                </div>
                <Form
                  key="select-country"
                  onSubmit={ onSuccess }
                >
                    { this.renderCountryListDropDown() }
                    { is_US_Country ? this.renderUS_AgeSelection() : this.renderAgeSelection() }
                    { this.renderCheckBox() }
                    { this.renderAgreeButton() }
                </Form>
                { this.renderNotePoints() }
            </>
        );
    }

    renderCloseButton() {
        const { handlePopup } = this.props;

        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label="Close"
              onClick={ handlePopup }
            >
                <CloseIcon />
            </button>
        );
    }

    render() {
        const {
            showCountrySelectionPopup, showCountrySelection
        } = this.props;

        return (
            <>
                <div block="StoreSwitcherV2" onClick={ showCountrySelectionPopup }>
                    { this.renderButton() }
                </div>
                <FooterPopup fromFooter={ showCountrySelection }>
                <div className="popup">
                    { this.renderCloseButton() }
                    <div block="SelectCountry" elem="selectCountryLogo">
                    <svg width="154" height="40" viewBox="0 0 154 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1_1364)">
                        <path d="M9.7437 29.1471C7.67285 29.1471 6.02813 28.5683 4.80953 27.4108C4.20617 26.836 3.73135 26.1399 3.4163 25.3684C3.10125 24.5969 2.95311 23.7674 2.98163 22.9345V15.02H0.241699V10.891H2.98163V5.93016L8.11806 5.38065V10.891H10.858V15.02H8.11806V22.2019C8.11806 23.1737 8.32158 23.8593 8.72863 24.2587C8.93906 24.4579 9.18729 24.6129 9.45865 24.7145C9.73001 24.8161 10.019 24.8622 10.3085 24.8502C10.7746 24.8093 11.2367 24.7301 11.6899 24.6136L12.7698 28.2885C11.8721 28.8669 10.8229 29.1657 9.75515 29.1471" fill="white" />
                        <path d="M19.9135 10.8567H14.7961V29.1471H19.9135V10.8567Z" fill="white" />
                        <path d="M40.2837 11.4215V27.6856C40.2837 30.2296 39.5205 32.3666 37.9941 34.0966C37.2787 34.9279 36.3969 35.5999 35.4057 36.0692C34.4145 36.5385 33.3358 36.7947 32.2394 36.8212L31.1518 36.9281C27.6182 36.8568 24.877 35.8469 22.9282 33.8981L25.3018 31.1544C26.7323 32.169 28.4264 32.7469 30.1787 32.8182C32.1249 32.8182 33.4428 32.3615 34.1322 31.4482C34.8043 30.5872 35.1684 29.5258 35.1663 28.4335V27.5024C33.8241 28.5901 32.1427 29.1721 30.4153 29.1471C27.6169 29.1471 25.5155 28.3241 24.1112 26.6781C22.7069 25.0321 22.0009 22.8086 21.9933 20.0076C21.9933 17.3288 22.7565 15.1371 24.2829 13.4326C25.8094 11.728 27.9718 10.8758 30.7702 10.8758C32.8385 10.9979 34.3001 11.5512 35.1549 12.5358V10.8758L40.2837 11.4215ZM35.1663 21.6562V18.6987C34.9119 16.6482 33.6323 15.4487 31.3274 15.1002C28.2745 15.207 26.6311 16.7233 26.397 19.6489C26.5141 23.0579 28.1575 24.8222 31.3274 24.9418C33.645 24.578 34.9247 23.4828 35.1663 21.6562Z" fill="white" />
                        <path d="M42.3176 20.0076C42.3176 17.3288 43.1712 15.1371 44.8782 13.4326C46.5853 11.728 48.777 10.8758 51.4533 10.8758C54.1322 10.8758 56.2641 11.728 57.849 13.4326C59.434 15.1371 60.2264 17.3288 60.2264 20.0076V21.8355H47.6334C47.674 22.2855 47.8088 22.7219 48.029 23.1164C48.2493 23.5108 48.55 23.8545 48.9118 24.1252C49.6492 24.6751 50.5488 24.9638 51.4686 24.9456C53.1134 24.9702 54.7213 24.457 56.0478 23.4841L58.9748 26.411C57.6315 28.2351 55.1332 29.1484 51.48 29.1509C48.8011 29.1509 46.6094 28.2974 44.9049 26.5904C43.2004 24.8833 42.3469 22.6903 42.3443 20.0115M54.0406 15.9016C53.2941 15.368 52.3995 15.0811 51.4819 15.0811C50.5643 15.0811 49.6697 15.368 48.9232 15.9016C48.5666 16.1638 48.2801 16.51 48.0891 16.9093C47.8981 17.3087 47.8084 17.7489 47.828 18.1912H55.1358C55.1544 17.7491 55.0643 17.3092 54.8733 16.91C54.6824 16.5108 54.3964 16.1645 54.0406 15.9016Z" fill="white" />
                        <path d="M67.3777 12.883C67.9892 12.2345 68.7292 11.7206 69.5506 11.3743C70.3719 11.028 71.2565 10.8569 72.1478 10.872C72.7172 10.8696 73.2856 10.9194 73.8459 11.0208L73.0445 16.0618C72.3192 15.4822 71.4121 15.178 70.484 15.2032C69.245 15.2897 68.2096 16.1916 67.3777 17.9088V29.1471H62.2642V11.4024L67.3777 10.8567V12.883Z" fill="white" />
                        <path d="M122.302 17.5234V29.1471H117.185V11.4024L122.302 10.8567V12.5167C123.502 11.4315 125.07 10.8435 126.687 10.872C129.005 10.872 130.772 11.5729 131.988 12.9746C133.175 14.3064 133.822 16.0332 133.804 17.8172V29.1471H128.698V19.0803C128.698 17.8643 128.393 16.9204 127.782 16.2488C127.176 15.5772 126.256 15.2261 125.027 15.1879C123.966 15.2592 123.058 16.0326 122.302 17.5081" fill="white" />
                        <path d="M135.853 20.0076C135.853 17.3288 136.706 15.1371 138.41 13.4326C140.115 11.728 142.308 10.8758 144.989 10.8758C147.668 10.8758 149.8 11.728 151.385 13.4326C152.97 15.1371 153.761 17.3288 153.758 20.0076V21.8355H141.165C141.207 22.2853 141.342 22.7213 141.562 23.1156C141.782 23.51 142.083 23.8539 142.444 24.1252C143.182 24.6759 144.083 24.9646 145.004 24.9456C146.649 24.9696 148.257 24.4565 149.583 23.4841L152.507 26.4072C151.168 28.2363 148.671 29.1509 145.016 29.1509C142.332 29.1509 140.139 28.2974 138.437 26.5904C136.735 24.8833 135.883 22.6903 135.88 20.0115M147.542 15.8977C146.795 15.3641 145.901 15.0773 144.983 15.0773C144.066 15.0773 143.171 15.3641 142.425 15.8977C142.069 16.1607 141.783 16.507 141.592 16.9062C141.401 17.3053 141.311 17.7453 141.329 18.1874H148.637C148.656 17.7453 148.566 17.3053 148.375 16.9062C148.184 16.507 147.898 16.1607 147.542 15.8977Z" fill="white" />
                        <path d="M19.9097 6.48731C19.9089 6.99282 19.7583 7.48677 19.4769 7.90673C19.1955 8.32668 18.7959 8.6538 18.3287 8.84673C17.8614 9.03966 17.3475 9.08975 16.8518 8.99066C16.3561 8.89158 15.9008 8.64777 15.5437 8.29005C15.1865 7.93233 14.9433 7.47675 14.845 6.98089C14.7466 6.48503 14.7975 5.97115 14.9911 5.50418C15.1848 5.03722 15.5125 4.63813 15.9328 4.35736C16.3532 4.07658 16.8474 3.92673 17.3529 3.92673C17.689 3.92673 18.0218 3.99299 18.3322 4.12171C18.6427 4.25044 18.9247 4.43911 19.1622 4.67693C19.3996 4.91476 19.5879 5.19706 19.7161 5.5077C19.8444 5.81835 19.9102 6.15123 19.9097 6.48731Z" fill="#E84620" />
                        <path d="M95.6434 15.333C96.4819 14.9409 97.3404 14.5932 98.2154 14.2912C98.2154 14.2912 92.0677 14.6728 93.6361 18.8704C93.6361 18.8704 92.8195 16.5808 95.6548 15.3444" fill="#E8492C" />
                        <path d="M98.7611 12.1198C98.7611 12.1198 89.4728 12.7075 91.8273 19.0231C91.8273 19.0231 91.274 15.2452 94.8802 13.6806C98.0475 12.3221 98.7649 12.1046 98.7649 12.1046" fill="#E8492C" />
                        <path d="M105.222 17.7027C105.275 17.8867 105.366 18.0574 105.49 18.2039C105.613 18.3504 105.766 18.4694 105.938 18.5532C106.11 18.637 106.298 18.6837 106.49 18.6904C106.681 18.697 106.872 18.6635 107.05 18.5919C106.761 17.8479 106.24 17.2165 105.565 16.7907C105.391 16.7023 105.226 16.5962 105.073 16.474C105.013 16.8933 105.065 17.3209 105.222 17.7142" fill="#E8492C" />
                        <path d="M95.1053 3.36798e-08C90.7995 -0.000249698 86.6084 1.38831 83.1546 3.95945C79.7007 6.53059 77.1683 10.1472 75.9333 14.2721C78.418 11.2894 81.6651 9.03639 85.3288 7.75289C88.9925 6.46938 92.9357 6.20345 96.7385 6.9834C97.1201 7.05972 97.456 7.13604 97.7651 7.21236C98.1441 7.27507 98.5308 7.27507 98.9099 7.21236C99.7723 6.91853 103.165 5.60962 103.489 5.65541C103.814 5.7012 102.398 7.35356 102.516 8.92578C102.634 10.498 102.592 10.0515 103.554 10.7995C104.516 11.5474 106.607 12.635 107.313 14.4553C108.019 16.2755 109.076 19.3284 109.728 20.3663C110.381 21.4043 111.198 23.133 110.56 23.984C110.262 24.4471 109.807 24.7872 109.278 24.9418C109.278 24.9418 109.145 25.4455 108.286 25.7661C107.474 26.0087 106.646 26.1973 105.809 26.3309C105.809 26.3309 105.115 26.888 104.924 26.8308C104.733 26.7735 105.016 26.3499 104.993 26.1439C104.993 26.0485 101.154 23.5871 100.532 23.7855C100.413 23.8237 99.7036 24.0031 100.2 24.8388C100.864 25.9607 103.226 27.8115 103.802 27.5978C104.565 27.3116 104.916 27.5978 103.401 28.5442C101.772 29.5631 101.345 28.9792 100.864 29.8111C100.383 30.643 99.4976 32.055 98.0017 29.7653C95.464 25.8996 93.8689 22.8163 91.1556 22.7285C88.9751 22.7306 86.8705 23.5291 85.2373 24.9739C83.6042 26.4187 82.555 28.4103 82.2871 30.5743C81.9055 33.8638 83.7143 36.2984 85.6109 37.6111C88.1611 38.9829 90.9786 39.785 93.8689 39.9623C96.7592 40.1395 99.6536 39.6876 102.352 38.6377C105.051 37.5878 107.49 35.965 109.5 33.8811C111.511 31.7972 113.046 29.302 113.999 26.5675C114.28 25.7114 114.512 24.8399 114.693 23.9573C114.895 22.9696 115.022 21.9683 115.075 20.9616C115.09 20.6462 115.098 20.3256 115.098 20C115.098 14.697 112.992 9.61107 109.242 5.86056C105.493 2.11005 100.408 0.0020237 95.1053 3.36798e-08Z" fill="#E84620" />
                        <path d="M90.2016 9.06697C90.2016 9.06697 73.5368 15.1269 81.375 26.0561C81.375 26.0561 78.2497 19.0689 83.9776 14.1691C89.0072 9.86834 90.2016 9.06697 90.2016 9.06697Z" fill="#E8492C" />
                        <path d="M91.0984 11.2345C91.0984 11.2345 78.4367 15.7909 84.2295 23.61C84.2295 23.61 81.9399 18.5919 86.3207 14.9895C90.1673 11.826 91.0794 11.2345 91.0794 11.2345" fill="#E8492C" />
                        </g>
                        <defs>
                        <clipPath id="clip0_1_1364">
                        <rect width="153.517" height="40" fill="white" transform="translate(0.241699)" />
                        </clipPath>
                        </defs>
                    </svg>
                    </div>
                    <div block="StoreSwitcherV2" elem="Wrapper">
                        { this.renderContent() }
                    </div>
                </div>
                </FooterPopup>
            </>
        );
    }
}

export default StoreSwitcherV2Component;
