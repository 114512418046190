import { WishlistQuery as SourceWishlistQuery } from 'SourceQuery/Wishlist.query';
import { Field } from 'Util/Query';

/** @namespace Tigerone/Query/Wishlist/Query */
export class WishlistQuery extends SourceWishlistQuery {
    // eslint-disable-next-line no-magic-numbers
    _getWishlistFields(currentPage, pageSize) {
        return [
            'id',
            'updated_at',
            'items_count',
            'creators_name',
            this._getPageInfoField(),
            this._getItemsField(currentPage, pageSize)
        ];
    }

    _getPageInfoField() {
        return new Field('page_info')
            .addFieldList(this._getPageInfoFields());
    }

    _getPageInfoFields() {
        return [
            'current_page',
            'page_size',
            'total_pages'
        ];
    }

    _getItemsField(currentPage, pageSize) {
        return new Field('items')
            .addArgument('currentPage', 'Int', currentPage)
            .addArgument('pageSize', 'Int', pageSize)
            .addFieldList(this._getItemsFields());
    }

    // eslint-disable-next-line no-magic-numbers
    getWishlistQuery(sharingCode, currentPage = 1, pageSize = 10) {
        const field = new Field('s_wishlist')
            .setAlias('wishlist')
            .addArgument('currentPage', 'Int', currentPage)
            .addArgument('pageSize', 'Int', pageSize)
            .addFieldList(this._getWishlistFields(currentPage, pageSize));

        if (sharingCode) {
            field.addArgument('sharing_code', 'ID', sharingCode);
        }

        return field;
    }
}

export default new WishlistQuery();
