/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    ADDRESS_POPUP_ID
} from 'Component/MyAccountAddressPopup/MyAccountAddressPopup.config';
import {
    MyAccountAddressTableContainer as SourceMyAccountAddressTableContainer
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.container';
import { showPopup } from 'Store/Popup/Popup.action';
import { Addresstype } from 'Type/Account.type';
import { MixType } from 'Type/Common.type';
import { CountriesType } from 'Type/Config.type';

/** @namespace Tigerone/Component/MyAccountAddressTable/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    countries: state.ConfigReducer.countries,
    allCountries: state.ConfigReducer?.allCountries
});

/** @namespace Tigerone/Component/MyAccountAddressTable/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showEditPopup: (payload) => dispatch(showPopup(ADDRESS_POPUP_ID, payload))
});

/** @namespace Tigerone/Component/MyAccountAddressTable/Container */
export class MyAccountAddressTableContainer extends SourceMyAccountAddressTableContainer {
    static propTypes = {
        mix: MixType,
        address: Addresstype.isRequired,
        showEditPopup: PropTypes.func.isRequired,
        countries: CountriesType.isRequired,
        showAdditionalFields: PropTypes.bool,
        showActions: PropTypes.bool,
        title: PropTypes.string,
        allCountries: CountriesType.isRequired
    };

    containerProps() {
        const {
            address,
            countries,
            mix,
            showAdditionalFields,
            showActions,
            title,
            allCountries
        } = this.props;

        return {
            address,
            countries,
            mix,
            showAdditionalFields,
            showActions,
            title,
            allCountries
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressTableContainer);
