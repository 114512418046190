/* eslint-disable no-magic-numbers */
/* eslint-disable fp/no-let */
/* eslint-disable consistent-return */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable max-len */
import Payment from 'payment';
import { connect } from 'react-redux';

import { KLARNA, PURCHASE_ORDER } from 'Component/CheckoutPayments/CheckoutPayments.config';
import { STORE_IN_PICK_UP_METHOD_CODE } from 'Component/StoreInPickUp/StoreInPickUp.config';
import {
    CheckoutBillingContainer as SourceCheckoutBillingContainer,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CheckoutBilling/CheckoutBilling.container';
import { setPaymentMethods } from 'Store/Checkout/Checkout.action';
import {
    getFormFields,
    setAddressesInFormObject,
    trimCheckoutAddress,
    trimCheckoutCustomerAddress
} from 'Util/Address';
import BrowserDatabase from 'Util/BrowserDatabase';
import {
    formatCreditCardNumber
} from 'Util/CreditCardValidation';
import transformToNameValuePair from 'Util/Form/Transform';

export const PAYTRIOT_PAYMENT_CODE = 'paytriot_gateway';
export const NMI_PAYMENT_CODE = 'rootways_nmi_option';

export const ACH_PAYMENT_CODE = 'accept_blue_ach';

/** @namespace Tigerone/Component/CheckoutBilling/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    checkoutData: state.CheckoutReducer
});

/** @namespace Tigerone/Component/CheckoutBilling/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setPaymentMethods: (state) => dispatch(setPaymentMethods(state))
});

/** @namespace Tigerone/Component/CheckoutBilling/Container */
export class CheckoutBillingContainer extends SourceCheckoutBillingContainer {
    static defaultProps = {
        newShippingId: 0,
        termsAreEnabled: false,
        cartTotalSubPrice: null
    };

    __construct(props) {
        super.__construct(props);

        const { paymentMethods } = props;
        const [method] = paymentMethods;
        const { code: paymentMethod } = method || {};

        const customerData = BrowserDatabase.getItem('customer') || {};

        this.state = {
            isSameAsShipping: this.isSameShippingAddress(customerData),
            isMounted: false,
            selectedCustomerAddressId: 0,
            prevPaymentMethods: paymentMethods,
            paymentMethod,
            isValidCardDetails: true
        };
    }

    componentDidMount() {
        const { customer: { default_billing } } = this.props;

        if (!parseInt(default_billing, 10)) {
            this.setState({ isSameAsShipping: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { customer: { default_billing, default_shipping }, newShippingId } = this.props;
        const { isMounted, isSameAsShipping: currIsSameAsShipping } = this.state;
        const { newShippingId: prevNewShippingId } = prevProps;
        const { isSameAsShipping: prevIsSameAsShipping } = prevState;

        const isSameAsShipping = this.isSameShippingAddress({ default_billing, default_shipping });

        // default billing & shipping are undefined on initial mount
        // wait until they become assigned to real values
        // then check for isSameAsShipping condition
        if (!isMounted && parseInt(default_billing, 10)) {
            this.setState({ isSameAsShipping, isMounted: true });
        }

        if (prevNewShippingId !== newShippingId) {
            this.setState({ isSameAsShipping });
        }

        if (prevNewShippingId !== newShippingId && !parseInt(default_billing, 10)) {
            this.setState({ isSameAsShipping: true });
        }

        if (prevIsSameAsShipping !== currIsSameAsShipping && currIsSameAsShipping) {
            this.onAddressSelect(
                // if the user selected a shipping address different from default
                newShippingId > 0 ? newShippingId : Number(default_shipping)
            );
        }
    }

    containerProps() {
        const {
            cartTotalSubPrice,
            paymentMethods,
            selectedShippingMethod,
            setDetailsStep,
            setLoading,
            shippingAddress,
            termsAndConditions,
            termsAreEnabled,
            totals,
            setPaymentMethod,
            companyData,
            checkoutData,
            paymentMethod,
            showError
        } = this.props;
        const { isSameAsShipping, isValidCardDetails } = this.state;

        return {
            cartTotalSubPrice,
            paymentMethods,
            isSameAsShipping,
            selectedShippingMethod,
            setDetailsStep,
            setLoading,
            shippingAddress,
            termsAndConditions,
            termsAreEnabled,
            totals,
            setPaymentMethod,
            companyData,
            paymentMethod,
            isValidCardDetails,
            checkoutData,
            showError
        };
    }

    onAddressSelect(id) {
        this.setState({ selectedCustomerAddressId: id });
    }

    _getAddress(fields) {
        const { addressLinesQty } = this.props;

        const {
            isSameAsShipping,
            selectedCustomerAddressId
        } = this.state;

        const formFields = getFormFields(fields, addressLinesQty);

        if (isSameAsShipping) {
            return this.getBillingSameAsShipping();
        }

        if (!selectedCustomerAddressId) {
            const joinedStreetAddressFields = setAddressesInFormObject(formFields, addressLinesQty, 'street_');

            return trimCheckoutAddress(joinedStreetAddressFields);
        }

        const { customer: { addresses } } = this.props;
        const address = addresses.find(({ id }) => id === selectedCustomerAddressId);

        return {
            ...trimCheckoutCustomerAddress(address),
            save_in_address_book: false
        };
    }

    _getPaymentData(fields, asyncData) {
        const { paymentMethod: code } = this.state;

        switch (code) {
        case KLARNA:
            const [{ authorization_token }] = asyncData;

            return {
                code,
                additional_data: {
                    authorization_token
                }
            };

        case PURCHASE_ORDER:
            const { purchaseOrderNumber } = fields;

            return {
                code,
                purchase_order_number: purchaseOrderNumber
            };

        case NMI_PAYMENT_CODE: {
            const {
                creditCardNumber, expireDate, cvc
            } = fields;

            const splitExpireDate = expireDate.split('/');
            const joinCardNumber = creditCardNumber.split(' ').join('');
            let cardTypeCode;

            switch (Payment.fns.cardType(creditCardNumber)) {
            case 'visa':
                cardTypeCode = 'VI';
                break;
            case 'mastercard':
                cardTypeCode = 'MC';
                break;
            case 'amex':
                cardTypeCode = 'AE';
                break;

            default:
                break;
            }

            return {
                code,
                additional_data: {
                    cc_number: joinCardNumber,
                    cc_exp_month: splitExpireDate[0],
                    cc_exp_year: `20${splitExpireDate[1]}`,
                    cc_cid: cvc,
                    cc_type: cardTypeCode
                }
            };
        }

        case PAYTRIOT_PAYMENT_CODE: {
            const {
                creditCardNumber, expireDate, cvc
            } = fields;

            const splitExpireDate = expireDate.split('/');
            const joinCardNumber = creditCardNumber.split(' ').join('');

            return {
                code,
                additional_data: {
                    cc_number: joinCardNumber,
                    cc_exp_month: splitExpireDate[0],
                    cc_exp_year: `20${splitExpireDate[1]}`,
                    cc_cid: cvc
                    // cc_type: Payment.fns.cardType(creditCardNumber)
                }
            };
        }

        case ACH_PAYMENT_CODE: {
            const {
                Routing_Number,
                Account_Number
            } = fields;

            return {
                code,
                additional_data: {
                    routing_number: Routing_Number,
                    account_number: Account_Number
                }
            };
        }

        default:
            return { code };
        }
    }

    _getValidCardDetails(fields) {
        const {
            creditCardNumber, expireDate, cvc
        } = fields;

        if (!creditCardNumber || !expireDate || !cvc) {
            this.setState({
                isValidCardDetails: false
            });

            return false;
        }

        this.setState({
            isValidCardDetails: true
        });

        return true;
    }

    async onPaymentMethodSelect(code) {
        const { setPaymentMethod, setPaymentMethods } = this.props;

        await setPaymentMethods(code);
        this.setState({ paymentMethod: code });
        setPaymentMethod(code);
    }

    isSameShippingAddress({
        default_shipping,
        default_billing
    }) {
        const {
            totals: { is_virtual },
            selectedShippingMethod,
            newShippingId
        } = this.props;

        if (is_virtual) {
            return false;
        }

        if (selectedShippingMethod === STORE_IN_PICK_UP_METHOD_CODE) {
            return false;
        }

        // if the user selected a shipping address different from default
        if (newShippingId > 0) {
            return newShippingId === parseInt(default_billing, 10);
        }

        if (!parseInt(default_billing, 10)) {
            return true;
        }

        // otherwise use the default values
        return default_shipping === default_billing;
    }

    onBillingSuccess(form, fields, asyncData) {
        const { savePaymentInformation, showErrorNotification } = this.props;
        const { isSameAsShipping } = this.state;

        const extractedFields = transformToNameValuePair(fields);
        const address = this._getAddress(extractedFields);
        const paymentMethod = this._getPaymentData(extractedFields, asyncData);
        const isCreditCardDetailsValid = this._getValidCardDetails(extractedFields);

        const { code } = paymentMethod;
        const { creditCardNumber, cvc } = extractedFields;

        if (creditCardNumber && !formatCreditCardNumber(creditCardNumber, code)?.isCardAvailable) {
            return showErrorNotification(__('Invalid Card Number!'));
        }

        if (cvc && cvc.length < 3) {
            return showErrorNotification(__('Invalid Cvc Number!'));
        }

        if ((code === PAYTRIOT_PAYMENT_CODE || code === NMI_PAYMENT_CODE)) {
            if (isCreditCardDetailsValid) {
                savePaymentInformation({
                    billing_address: address,
                    paymentMethod,
                    same_as_shipping: isSameAsShipping
                });
            }
        } else {
            savePaymentInformation({
                billing_address: address,
                paymentMethod,
                same_as_shipping: isSameAsShipping
            });
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBillingContainer);
