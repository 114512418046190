import {
    APPEND_PAGE as SOURCE_APPEND_PAGE,
    UPDATE_LOAD_STATUS as SOURCE_UPDATE_LOAD_STATUS,
    UPDATE_PAGE_LOAD_STATUS as SOURCE_UPDATE_PAGE_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_ITEMS as SOURCE_UPDATE_PRODUCT_LIST_ITEMS
} from 'SourceStore/ProductList/ProductList.action';
import {
    defaultConfig as sourceDefaultConfig,
    getInitialState as sourceGetInitialState,
    ProductListReducer as sourceProductListReducer
} from 'SourceStore/ProductList/ProductList.reducer';

export const APPEND_PAGE = SOURCE_APPEND_PAGE;
export const UPDATE_LOAD_STATUS = SOURCE_UPDATE_LOAD_STATUS;
export const UPDATE_PAGE_LOAD_STATUS = SOURCE_UPDATE_PAGE_LOAD_STATUS;
export const UPDATE_PRODUCT_LIST_ITEMS = SOURCE_UPDATE_PRODUCT_LIST_ITEMS;

// TODO: implement getInitialState
export const getInitialState = sourceGetInitialState;

// TODO: implement defaultConfig
export const defaultConfig = sourceDefaultConfig;

/** @namespace Tigerone/Store/ProductList/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        min_price = 0,
        max_price = 0
    } = action;

    if (type === UPDATE_PRODUCT_LIST_ITEMS) {
        return {
            ...sourceProductListReducer(state, action),
            min_price,
            max_price
        };
    }

    return sourceProductListReducer(state, action);
};

export default ProductListReducer;
