/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import FieldGroup from 'Component/FieldGroup';
import {
    MyAccountAddressForm as SourceMyAccountAddressForm
} from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.component';
import { showNotification } from 'Store/Notification/Notification.action';
import { Addresstype } from 'Type/Account.type';
import { CountriesType } from 'Type/Config.type';
import {
    trimCustomerAddress
} from 'Util/Address';
import transformToNameValuePair from 'Util/Form/Transform';

import myAccountAddressForm from './MyAccountAddressForm.form';

import 'react-phone-input-2/lib/style.css';
import './MyAccountAddressForm.style.scss';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Tigerone/Component/MyAccountAddressForm/Component/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});
/** @namespace Tigerone/Component/MyAccountAddressForm/Component */
export class MyAccountAddressFormComponent extends SourceMyAccountAddressForm {
     static propTypes = {
         address: Addresstype.isRequired,
         countries: CountriesType.isRequired,
         defaultCountry: PropTypes.string.isRequired,
         addressLinesQty: PropTypes.number.isRequired,
         showVatNumber: PropTypes.bool.isRequired,
         regionDisplayAll: PropTypes.bool.isRequired,
         onCountryChange: PropTypes.func.isRequired,
         onZipcodeChange: PropTypes.func.isRequired,
         onCityChange: PropTypes.func.isRequired,
         onRegionChange: PropTypes.func.isRequired,
         onRegionIdChange: PropTypes.func.isRequired,
         countryId: PropTypes.string.isRequired,
         isStateRequired: PropTypes.bool,
         currentCity: PropTypes.string,
         currentRegion: PropTypes.string,
         currentZipcode: PropTypes.string,
         currentRegionId: PropTypes.number
     };

    state = {
        handlevalidation: true
    };

     static defaultProps = {
         currentZipcode: null,
         currentCity: null,
         currentRegion: null,
         currentRegionId: null,
         isStateRequired: false
     };

     onError() {
         const { showNotification } = this.props;
         showNotification('info', __('Incorrect data! Please resolve all field validation errors.'));
         if (document.querySelector('.form-control ').value.length < 10) {
             this.setState({
                 handlevalidation: false
             });
         }
     }

     //#region GETTERS
     get fieldMap() {
         const {
             address,
             countries,
             addressLinesQty,
             regionDisplayAll,
             showVatNumber,
             defaultCountry,
             availableRegions,
             isStateRequired,
             countryId,
             currentRegion,
             currentCity,
             currentRegionId,
             currentZipcode,
             onCountryChange,
             onZipcodeChange,
             onCityChange,
             onRegionChange,
             onRegionIdChange
         } = this.props;

         return myAccountAddressForm({
             address,
             countries,
             addressLinesQty,
             regionDisplayAll,
             showVatNumber,
             defaultCountry,
             availableRegions,
             isStateRequired,
             countryId,
             currentRegion,
             currentCity,
             currentRegionId,
             currentZipcode,
             ...address
         }, {
             onCountryChange,
             onZipcodeChange,
             onCityChange,
             onRegionChange,
             onRegionIdChange
         });
     }

     getFormProps() {
         return {
             onSubmit: this.onSubmit.bind(this)
         };
     }

     renderSection(section) {
         const {
             fields,
             attr: {
                 name = ''
             } = {},
             name: sectionName
         } = section;

         // If contains attr fields then outputs data as fields
         if (Array.isArray(fields)) {
             return (
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                <FieldGroup { ...section } key={ name || sectionName }>
                    { fields.map(this.renderSection.bind(this)) }
                </FieldGroup>
             );
         }

         // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
         return <Field { ...section } key={ name } />;
     }

     /**
      * Creates / Updates address from entered data
      * @param form
      * @param fields
      */
     onSubmit(form, fields) {
         const { onSave, addressLinesQty } = this.props;
         const newAddress = transformToNameValuePair(fields);
         // Joins streets into one variable
         if (addressLinesQty > 1) {
             newAddress.street = [];
             // eslint-disable-next-line fp/no-loops,fp/no-let
             for (let i = 0; i < addressLinesQty; i++) {
                 if (newAddress[`street_${i}`]) {
                     newAddress.street.push(newAddress[`street_${i}`]);
                 }
             }
         }

         // Fixes region variable format
         const { region_id = 0, region_string: region } = newAddress;
         newAddress.region = { region_id: +region_id, region };

         // Filters out non-required options and save address
         onSave(trimCustomerAddress(newAddress));
     }

     //#region RENDERERS
     renderActions() {
         return (
            <div className="Save-Address">
                 <button
                   type={ FIELD_TYPE.submit }
                   block="Button"
                   mix={ { block: 'MyAccount', elem: 'Button' } }
                   mods={ { isHollow: true } }
                 >
                 { __('Save Address') }
                 </button>
            </div>
         );
     }
     //#endregion
}

export default MyAccountAddressFormComponent;
