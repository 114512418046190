import {
    UPDATE_CONFIG as SOURCE_UPDATE_CONFIG,
    UPDATE_CONFIG_DEVICE as SOURCE_UPDATE_CONFIG_DEVICE,
    updateConfig as sourceUpdateConfig,
    updateConfigDevice as sourceUpdateConfigDevice
} from 'SourceStore/Config/Config.action';

export const UPDATE_CONFIG = SOURCE_UPDATE_CONFIG;
export const UPDATE_CONFIG_DEVICE = SOURCE_UPDATE_CONFIG_DEVICE;
export const updateConfig = sourceUpdateConfig;
export const updateConfigDevice = sourceUpdateConfigDevice;

export const SAVE_CURRENT_PAGE = 'SAVE_CURRENT_PAGE';

/** @namespace Tigerone/Store/Config/Action/saveCurrentPage */
export const saveCurrentPage = (pathname) => ({
    type: SAVE_CURRENT_PAGE,
    pathname
});
