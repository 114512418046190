import {
    getInitialState as sourceGetInitialState,
    MyAccountReducer as sourceMyAccountReducer
} from 'SourceStore/MyAccount/MyAccount.reducer';
import {
    UPDATE_COMPANY_DETAILS,
    UPDATE_CUSTOMER_DETAILS as SOURCE_UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_IS_LOADING as SOURCE_UPDATE_CUSTOMER_IS_LOADING,
    UPDATE_CUSTOMER_IS_LOCKED as SOURCE_UPDATE_CUSTOMER_IS_LOCKED,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL as SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS as SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS as SOURCE_UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS as SOURCE_UPDATE_CUSTOMER_SIGN_IN_STATUS
} from 'Store/MyAccount/MyAccount.action';

export const UPDATE_CUSTOMER_DETAILS = SOURCE_UPDATE_CUSTOMER_DETAILS;
export const UPDATE_CUSTOMER_IS_LOADING = SOURCE_UPDATE_CUSTOMER_IS_LOADING;
export const UPDATE_CUSTOMER_IS_LOCKED = SOURCE_UPDATE_CUSTOMER_IS_LOCKED;
export const UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL = SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL;
export const UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS = SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS;
export const UPDATE_CUSTOMER_PASSWORD_RESET_STATUS = SOURCE_UPDATE_CUSTOMER_PASSWORD_RESET_STATUS;
export const UPDATE_CUSTOMER_SIGN_IN_STATUS = SOURCE_UPDATE_CUSTOMER_SIGN_IN_STATUS;

/** @namespace Tigerone/Store/MyAccount/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    emailForgotPassword: '',
    company: null
});

/** @namespace Tigerone/Store/MyAccount/Reducer/MyAccountReducer */
export const MyAccountReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type, email
    } = action;

    if (type === UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL) {
        return {
            ...sourceMyAccountReducer(state, action),
            emailForgotPassword: email
        };
    }

    if (type === UPDATE_COMPANY_DETAILS) {
        const { companyData } = action;
        return {
            ...sourceMyAccountReducer(state, action),
            company: companyData
        };
    }

    return sourceMyAccountReducer(state, action);
};

export default MyAccountReducer;
