/* eslint-disable react/jsx-no-bind */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import { PureComponent } from 'react';

import CheckoutAddressForm from 'Component/CheckoutAddressForm';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';

import { CHECKOUT_ADDRESS_POPUP_ID } from './CheckoutAddressPopup.config';

import './CheckoutAddressPopup.style';

/** @namespace Tigerone/Component/CheckoutAddressPopup/Component */
export class CheckoutAddressPopupComponent extends PureComponent {
    renderContent() {
        const {
            onShippingEstimationFieldsChange, isSubmitted, hideActiveOverlay, formPortalId, setNewFormAddress, saveAddress
        } = this.props;

        return (
            <CheckoutAddressForm
              onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
              address={ {} }
              id={ formPortalId }
              isSubmitted={ isSubmitted }
              hideActiveOverlay={ hideActiveOverlay }
              setNewFormAddress={ setNewFormAddress }
              saveAddress={ saveAddress }
            />
        );
    }

    render() {
        const { isLoading } = this.props;

        return (
            <Popup
              id={ CHECKOUT_ADDRESS_POPUP_ID }
              clickOutside
              mix={ { block: 'MyAccountAddressPopup' } }
              isMobileClick
            >
                <div>
                    <Loader isLoading={ isLoading } />
                    { this.renderContent() }
                </div>
            </Popup>
        );
    }
}
export default CheckoutAddressPopupComponent;
