/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import FieldSelect from 'Component/FieldSelect/FieldSelect.component';
import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';
import {
    EventsType,
    FieldAttrType,
    FieldOptionsType
} from 'SourceType/Field.type';

/** @namespace Tigerone/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    static propTypes = {
        // Field attributes
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        options: FieldOptionsType.isRequired,
        setRef: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        noPlaceholder: PropTypes.bool,
        changeValueOnDoubleClick: PropTypes.bool,
        isSortSelect: PropTypes.bool,
        style: PropTypes.bool
    };

    containerProps() {
        const {
            attr: {
                autoComplete,
                autocomplete,
                noPlaceholder,
                selectPlaceholder,
                ...attr
            } = {},
            events,
            setRef,
            isDisabled,
            isSortSelect,
            style
        } = this.props;

        const {
            isExpanded,
            isDropdownOpenUpwards,
            isScrollable,
            isSelectedOptionAvailable
        } = this.state;

        return {
            attr: {
                ...attr,
                autoComplete: autoComplete || autocomplete
            },
            events,
            setRef,
            isDisabled,
            isExpanded,
            isDropdownOpenUpwards,
            isScrollable,
            isSortSelect,
            isSelectedOptionAvailable,
            style,
            options: this.getOptions()
        };
    }

    render() {
        return (
            <FieldSelect
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}
export default FieldSelectContainer;
