/* eslint-disable max-len */
import { ChevronIcon as SourceChevronIcon } from 'SourceComponent/ChevronIcon/ChevronIcon.component';

/** @namespace Tigerone/Component/ChevronIcon/Component */
export class ChevronIconComponent extends SourceChevronIcon {
    render() {
        const { direction, style } = this.props;

        if (style) {
            return (
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.000000pt"
                  height="16.000000pt"
                  viewBox="0 0 16.000000 16.000000"
                  preserveAspectRatio="xMidYMid meet"
                  block="ChevronIcon"
                  mods={ { direction } }
                >

                    <g
                      transform="translate(0.000000,16.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                        <path d="M10 80 l0 -82 63 35 c34 20 62 41 62 47 0 6 -28 27 -62 47 l-63 35 0
    -82z"
                        />
                    </g>
                </svg>
            );
        }

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M5.8535 13.707L11.5605 7.99997L5.8535 2.29297L4.4395 3.70697L8.7325 7.99997L4.4395 12.293L5.8535 13.707Z" />
            </svg>
        );
    }
}
export default ChevronIconComponent;
