/* eslint-disable max-len */
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps
} from 'Component/MyAccountAddressForm/MyAccountAddressForm.container';
import {
    CheckoutAddressFormContainer as SourceCheckoutAddressFormContainer
} from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.container';

/** @namespace Tigerone/Component/CheckoutAddressForm/Container */
export class CheckoutAddressFormContainer extends SourceCheckoutAddressFormContainer {
    containerProps() {
        const {
            onShippingEstimationFieldsChange, clickProceedToBillingButton, hideActiveOverlay, setNewFormAddress, saveAddress
        } = this.props;

        return {
            onShippingEstimationFieldsChange,
            clickProceedToBillingButton,
            hideActiveOverlay,
            setNewFormAddress,
            saveAddress,
            ...super.containerProps()
        };
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressFormContainer);
