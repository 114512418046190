import {
    UPDATE_CUSTOMER_DETAILS as SOURCE_UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_IS_LOADING as SOURCE_UPDATE_CUSTOMER_IS_LOADING,
    UPDATE_CUSTOMER_IS_LOCKED as SOURCE_UPDATE_CUSTOMER_IS_LOCKED,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL as SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS as SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS as SOURCE_UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS as SOURCE_UPDATE_CUSTOMER_SIGN_IN_STATUS,
    updateCustomerDetails as sourceUpdateCustomerDetails,
    updateCustomerPasswordForgotEmail as sourceUpdateCustomerPasswordForgotEmail,
    updateCustomerPasswordForgotStatus as sourceUpdateCustomerPasswordForgotStatus,
    updateCustomerPasswordResetStatus as sourceUpdateCustomerPasswordResetStatus,
    updateCustomerSignInStatus as sourceUpdateCustomerSignInStatus,
    updateIsLoading as sourceUpdateIsLoading,
    updateIsLocked as sourceUpdateIsLocked
} from 'SourceStore/MyAccount/MyAccount.action';

export const UPDATE_CUSTOMER_SIGN_IN_STATUS = SOURCE_UPDATE_CUSTOMER_SIGN_IN_STATUS;
export const UPDATE_CUSTOMER_DETAILS = SOURCE_UPDATE_CUSTOMER_DETAILS;
export const UPDATE_CUSTOMER_PASSWORD_RESET_STATUS = SOURCE_UPDATE_CUSTOMER_PASSWORD_RESET_STATUS;
export const UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS = SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS;
export const UPDATE_CUSTOMER_IS_LOADING = SOURCE_UPDATE_CUSTOMER_IS_LOADING;
export const UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL = SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL;
export const UPDATE_CUSTOMER_IS_LOCKED = SOURCE_UPDATE_CUSTOMER_IS_LOCKED;
export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';

export const updateCustomerSignInStatus = sourceUpdateCustomerSignInStatus;
export const updateCustomerDetails = sourceUpdateCustomerDetails;
export const updateCustomerPasswordResetStatus = sourceUpdateCustomerPasswordResetStatus;
export const updateCustomerPasswordForgotStatus = sourceUpdateCustomerPasswordForgotStatus;
export const updateIsLoading = sourceUpdateIsLoading;
export const updateCustomerPasswordForgotEmail = sourceUpdateCustomerPasswordForgotEmail;
export const updateIsLocked = sourceUpdateIsLocked;

/** @namespace Tigerone/Store/MyAccount/Action/updateCompanyDetails */
export const updateCompanyDetails = (companyData) => ({
    type: UPDATE_COMPANY_DETAILS,
    companyData
});
