import {
    ADD_PRODUCT_TO_CART as SOURCE_ADD_PRODUCT_TO_CART,
    addProductToCart as sourceAddProductToCart,
    APPLY_COUPON_TO_CART as SOURCE_APPLY_COUPON_TO_CART,
    applyCouponToCart as sourceApplyCouponToCart,
    REMOVE_COUPON_FROM_CART as SOURCE_REMOVE_COUPON_FROM_CART,
    REMOVE_PRODUCT_FROM_CART as SOURCE_REMOVE_PRODUCT_FROM_CART,
    UPDATE_IS_LOADING_CART as SOURCE_UPDATE_IS_LOADING_CART,
    UPDATE_SHIPPING_PRICE as SOURCE_UPDATE_SHIPPING_PRICE,
    UPDATE_TOTALS as SOURCE_UPDATE_TOTALS,
    updateIsLoadingCart as sourceUpdateIsLoadingCart,
    updateShippingPrice as sourceUpdateShippingPrice,
    updateTotals as sourceUpdateTotals
} from 'SourceStore/Cart/Cart.action';

export const ADD_PRODUCT_TO_CART = SOURCE_ADD_PRODUCT_TO_CART;
export const REMOVE_PRODUCT_FROM_CART = SOURCE_REMOVE_PRODUCT_FROM_CART;
export const UPDATE_TOTALS = SOURCE_UPDATE_TOTALS;
export const APPLY_COUPON_TO_CART = SOURCE_APPLY_COUPON_TO_CART;
export const REMOVE_COUPON_FROM_CART = SOURCE_REMOVE_COUPON_FROM_CART;
export const UPDATE_SHIPPING_PRICE = SOURCE_UPDATE_SHIPPING_PRICE;
export const UPDATE_IS_LOADING_CART = SOURCE_UPDATE_IS_LOADING_CART;
export const addProductToCart = sourceAddProductToCart;
export const applyCouponToCart = sourceApplyCouponToCart;
export const updateIsLoadingCart = sourceUpdateIsLoadingCart;
export const updateShippingPrice = sourceUpdateShippingPrice;
export const updateTotals = sourceUpdateTotals;

export const CLEAR_CART = 'CLEAR_CART';
export const ADD_PROMO_PRODUCTS_TO_CART = 'ADD_PROMO_PRODUCTS_TO_CART';
export const REMOVE_SPECIFIC_CART_ITEMS = 'REMOVE_SPECIFIC_CART_ITEMS';
export const MOVE_CART_TO_WISHLIST = 'MOVE_CART_TO_WISHLIST';
export const APPLY_STORE_CREDIT = 'APPLY_STORE_CREDIT';
export const REMOVE_STORE_CREDIT = 'REMOVE_STORE_CREDIT';

/**
 * Update product list with new list (rewrite if already exists).
 * @param  {Array<Object>} items List of products returned from fetch
 * @param  {Number} totalItems Total number of products in this filter
 * @return {void}
 * @namespace Tigerone/Store/Cart/Action/addQuickOrderProductToCart */
export const addQuickOrderProductToCart = (newProduct) => ({
    type: ADD_PRODUCT_TO_CART,
    newProduct
});

/** @namespace Tigerone/Store/Cart/Action/clearCart */
export const clearCart = (cartId) => ({
    type: CLEAR_CART,
    cartId
});

/** @namespace Tigerone/Store/Cart/Action/addPromoProductsToCart */
export const addPromoProductsToCart = (cartId, cartItems) => ({
    type: ADD_PROMO_PRODUCTS_TO_CART,
    cartId,
    cartItems
});

/** @namespace Tigerone/Store/Cart/Action/removeProductFromCart */
export const removeProductFromCart = (product) => ({
    type: REMOVE_PRODUCT_FROM_CART,
    product
});

/** @namespace Tigerone/Store/Cart/Action/removeSpecificCartItems */
export const removeSpecificCartItems = (cartId, item_id) => ({
    type: REMOVE_SPECIFIC_CART_ITEMS,
    cartId,
    item_id
});

/** @namespace Tigerone/Store/Cart/Action/moveCartToWishlist */
export const moveCartToWishlist = (cartId, item_id) => ({
    type: MOVE_CART_TO_WISHLIST,
    cartId,
    item_id
});

/** @namespace Tigerone/Store/Cart/Action/removeCouponFromCart */
export const removeCouponFromCart = () => ({
    type: REMOVE_COUPON_FROM_CART
});

/** @namespace Tigerone/Store/Cart/Action/applyStoreCredit */
export const applyStoreCredit = (cartId) => ({
    type: APPLY_STORE_CREDIT,
    cartId
});

/** @namespace Tigerone/Store/Cart/Action/removeStoreCredit */
export const removeStoreCredit = (cartId) => ({
    type: REMOVE_STORE_CREDIT,
    cartId
});
