/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/**
 * MyAccount Mutations
 * @class MyAccount
 * @namespace Tigerone/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    _getCustomerFields() {
        const fields = super._getCustomerFields();
        fields.push(
            this._getStoreCreditField(),
            this._getOrdersField(),
            'customer_type'
        );

        return fields;
    }

    _getCompanyCredit() {
        return new Field('company')
            .addFieldList([
                this.getCompanyCreditFields()
            ]);
    }

    getCompanyCreditFields() {
        return new Field('credit')
            .addFieldList([
                'allow_exceed_amount',
                this.getAvailableCredit(),
                this.getCreditLimit(),
                this.getOutstandingBalance()
            ]);
    }

    getAvailableCredit() {
        return new Field('available_credit')
            .addFieldList([
                'currency',
                'value'
            ]);
    }

    getCreditLimit() {
        return new Field('credit_limit')
            .addFieldList([
                'currency',
                'value'
            ]);
    }

    getOutstandingBalance() {
        return new Field('outstanding_balance')
            .addFieldList([
                'currency',
                'value'
            ]);
    }

    _getOrdersField() {
        return new Field('orders')
            .addField(this._getOrdersFields());
    }

    _getOrdersFields() {
        return new Field('items')
            .addFieldList(this._getItemsFields());
    }

    _getItemsFields() {
        return ['id', 'increment_id', 'can_reorder', 'status', 'state', 'grand_total', 'created_at', this._getShippingAddressField()];
    }

    _getShippingAddressField() {
        return new Field('shipping_address')
            .addFieldList(this._getShippingAddressFields());
    }

    _getShippingAddressFields() {
        return ['firstname', 'lastname'];
    }

    _getStoreCreditField() {
        return new Field('store_credit')
            .addField(this._getStoreCreditFields())
            .addField(this._getStoreCreditItemFields());
    }

    _getStoreCreditFields() {
        return new Field('current_balance')
            .addFieldList(this._getBalanceFields());
    }

    _getStoreCreditItemFields() {
        return new Field('balance_history')
            .addField(this._getCurrentBalanceHistoryFields());
    }

    _getCurrentBalanceHistoryFields() {
        return new Field('items')
            .addFieldList(this._getItemsHistoryFields());
    }

    _getItemsHistoryFields() {
        return ['action', 'date_time_changed', 'information', this._getActualBalanceField(), this._getBalanceChangeField()];
    }

    _getActualBalanceField() {
        return new Field('actual_balance')
            .addFieldList(this._getBalanceFields());
    }

    _getBalanceChangeField() {
        return new Field(' balance_change')
            .addFieldList(this._getBalanceFields());
    }

    _getBalanceFields() {
        return [
            'currency',
            'value'
        ];
    }

    _getAddressFields() {
        const fields = super._getAddressFields();
        fields.push('company');

        return fields;
    }

    /**
     * Get SignIn mutation
     * @param {{email: String, password: String, gRecaptcha: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Field}
     * @memberof MyAccount
     */
    getSignInMutation(options) {
        const { email, password, gRecaptcha } = options;

        return new Field('generateCustomerToken')
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addArgument('recaptcha_key', 'String', gRecaptcha)
            .addField('token');
    }

    /**
     * Get ForgotPassword mutation
     * @param {{email: String, gRecaptcha: String}} options
     * @returns {Field}
     * @memberof MyAccount
     */
    getForgotPasswordMutation(options) {
        const { email, gRecaptcha } = options;

        return new Field('forgotPassword')
            .addArgument('email', 'String!', email)
            .addArgument('recaptcha_key', 'String', gRecaptcha)
            .addField('status');
    }
}

export default new MyAccountQuery();
