/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { CompareIcon as SourceCompareIcon } from 'SourceComponent/CompareIcon/CompareIcon.component';

import './CompareIcon.override.style';

/** @namespace Tigerone/Component/CompareIcon/Component */
export class CompareIconComponent extends SourceCompareIcon {
    static propTypes = {
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };

    render() {
        const { isActive } = this.props;

        return (
            <svg block="CompareIcon" mods={ { isActive } } xmlns="http://www.w3.org/2000/svg" width="13.343" height="16.52" viewBox="0 0 13.343 16.52">
                <path id="noun-compare-3843889" d="M179.593,55.995v9.531a1.906,1.906,0,0,1-1.906,1.906h-2.224a.635.635,0,1,1,0-1.271h2.224a.635.635,0,0,0,.635-.635V55.995a.635.635,0,0,0-.635-.635h-2.224a.635.635,0,1,1,0-1.271h2.224a1.906,1.906,0,0,1,1.906,1.906Zm-6.036-2.859V68.385a.635.635,0,1,1-1.271,0v-.953h-4.13a1.906,1.906,0,0,1-1.906-1.906V55.995a1.906,1.906,0,0,1,1.906-1.906h4.13v-.953a.635.635,0,1,1,1.271,0Zm-1.271,13.025v-10.8h-4.13a.635.635,0,0,0-.635.635v9.531a.635.635,0,0,0,.635.635Z" transform="translate(-166.25 -52.5)" fill="#e73b25" />
            </svg>
        );
    }
}

export default CompareIconComponent;
