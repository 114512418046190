/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    appendPage,
    UPDATE_PRODUCT_LIST_ITEMS,
    updateProductListItems
} from 'Store/ProductList/ProductList.action';

import { getCustomerData } from '../../data/customer';
import { fireProductSortEvent } from '../../event/sort';

const addFireProductSortEvent = (args, callback) => {
    const [state, action] = args;

    if (action.type !== UPDATE_PRODUCT_LIST_ITEMS) {
        return callback(...args);
    }

    const { currentArgs: { sort: oldSort } } = state;

    if (!oldSort) {
        return callback(...args);
    }
    // ^^^ skip if it's initial

    const { args: { sort }, customerId } = action;

    if (
        oldSort.sortDirection !== sort.sortDirection
        || oldSort.sortKey !== sort.sortKey
    ) {
        fireProductSortEvent(sort, customerId);
    }

    return callback(...args);
};

const aroundOnSuccess = async (functionArgs) => {
    const [data, dispatch, options] = functionArgs;
    const {
        products: {
            items,
            total_count,
            page_info: { total_pages } = {},
            min_price,
            max_price
        } = {}
    } = data;

    const { args, isNext } = options;
    const { currentPage } = args;

    if (isNext) {
        return dispatch(
            appendPage(
                items,
                currentPage
            )
        );
    }

    const { customerId } = await getCustomerData();

    return dispatch(
        {
            ...updateProductListItems(
                items,
                currentPage,
                total_count,
                total_pages,
                args,
                min_price,
                max_price,

            ),
            customerId
        }
    );
};

export default {
    'Store/ProductList/Reducer/ProductListReducer': {
        function: addFireProductSortEvent
    },
    'Store/ProductList/Dispatcher': {
        'member-function': {
            onSuccess: aroundOnSuccess
        }
    }
};
