import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedParameteredProducts } from 'Util/Product';

import {
    CLEAR_WISHLIST,
    REMOVE_ITEM_FROM_WISHLIST,
    UPDATE_ALL_PRODUCTS_IN_WISHLIST,
    UPDATE_IS_LOADING_IN_WISHLIST,
    UPDATE_ITEM_OPTIONS
} from './Wishlist.action';

export const PRODUCTS_IN_WISHLIST = 'wishlist_products';

/** @namespace Tigerone/Store/Wishlist/Reducer/getInitialState */
export const getInitialState = () => ({
    productsInWishlist: BrowserDatabase.getItem(PRODUCTS_IN_WISHLIST) || {},
    page_info: {},
    items_count: null,
    isLoading: true
});

/** @namespace Tigerone/Store/Wishlist/Reducer/deleteProperty */
export const deleteProperty = (key, { [key]: _, ...newObj }) => newObj;

/** @namespace Tigerone/Store/Wishlist/Reducer/removeItemFromWishlist */
export const removeItemFromWishlist = ({ item_id }, { productsInWishlist: initialProducts }) => {
    const productsInWishlist = deleteProperty(item_id, initialProducts) || {};

    BrowserDatabase.setItem(
        productsInWishlist,
        PRODUCTS_IN_WISHLIST
    );

    return { productsInWishlist };
};

/** @namespace Tigerone/Store/Wishlist/Reducer/clearWishlist */
export const clearWishlist = () => {
    const productsInWishlist = {};

    BrowserDatabase.setItem(productsInWishlist, PRODUCTS_IN_WISHLIST);

    return { productsInWishlist, items_count: 0 };
};

/** @namespace Tigerone/Store/Wishlist/Reducer/updateAllProductsInWishlist */
export const updateAllProductsInWishlist = (action) => {
    const { options: { productsToAdd: initialProducts = {}, page_info, items_count } } = action;

    const products = getIndexedParameteredProducts(initialProducts);

    BrowserDatabase.setItem(
        products,
        PRODUCTS_IN_WISHLIST
    );

    return {
        productsInWishlist: products, isLoading: false, page_info: page_info || {}, items_count: items_count || 0
    };
};

/** @namespace Tigerone/Store/Wishlist/Reducer/updateItemOptions */
export const updateItemOptions = (options, { productsInWishlist }) => {
    const { item_id } = options;
    const cleanedOptions = deleteProperty('item_id', options) || {};

    const products = {
        ...productsInWishlist,
        [item_id]: {
            ...productsInWishlist[item_id],
            wishlist: {
                ...productsInWishlist[item_id].wishlist,
                ...cleanedOptions
            }
        }
    };

    BrowserDatabase.setItem(
        products,
        PRODUCTS_IN_WISHLIST
    );

    return { productsInWishlist: products };
};

/** @namespace Tigerone/Store/Wishlist/Reducer/WishlistReducer */
export const WishlistReducer = (
    state = getInitialState(),
    action
) => {
    const { type, options } = action;

    switch (type) {
    case REMOVE_ITEM_FROM_WISHLIST:
        return {
            ...state,
            isLoading: false,
            ...removeItemFromWishlist(action, state)
        };

    case CLEAR_WISHLIST:
        return {
            ...state,
            ...clearWishlist()
        };

    case UPDATE_ALL_PRODUCTS_IN_WISHLIST:
        return {
            ...state,
            isLoading: false,
            ...updateAllProductsInWishlist(action)
        };

    case UPDATE_ITEM_OPTIONS:
        return {
            ...state,
            ...updateItemOptions(options, state)
        };

    case UPDATE_IS_LOADING_IN_WISHLIST:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default WishlistReducer;
