/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-len */
// import CheckoutAddressForm from 'Component/CheckoutAddressForm';
import AddIcon from 'Component/AddIcon';
import CheckoutAddressPopup from 'Component/CheckoutAddressPopup';
import CheckoutAddressTable from 'Component/CheckoutAddressTable';
import StoreSwitcherV2 from 'Component/StoreSwitcherV2';
import { BILLING_STEP, SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { CheckoutAddressBook as SourceCheckoutAddressBook } from 'SourceComponent/CheckoutAddressBook/CheckoutAddressBook.component';
import { getDefaultAddressLabel } from 'Util/Address';

/** @namespace Tigerone/Component/CheckoutAddressBook/Component */
export class CheckoutAddressBookComponent extends SourceCheckoutAddressBook {
    renderAddress(address, index) {
        const { onAddressSelect, selectedAddressId } = this.props;
        const addressNumber = index + 1;
        const { id } = address;
        const postfix = getDefaultAddressLabel(address);

        return (
            <CheckoutAddressTable
              onClick={ onAddressSelect }
              isSelected={ selectedAddressId === id }
              title={ __('Address #%s%s', addressNumber, postfix) }
              address={ address }
              key={ id }
            />
        );
    }

    expandCustomAddress() {
        const { onAddressSelect, openAddressPopup } = this.props;
        this.setState({ isCustomAddressExpanded: true });
        onAddressSelect({});
        openAddressPopup();
    }

    renderCustomAddress() {
        const {
            isBilling, onShippingEstimationFieldsChange, isSubmitted, setNewFormAddress, saveAddress
        } = this.props;
        const formPortalId = isBilling ? BILLING_STEP : SHIPPING_STEP;

        return (
            <CheckoutAddressPopup
              onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
              address={ {} }
              id={ formPortalId }
              isSubmitted={ isSubmitted }
              setNewFormAddress={ setNewFormAddress }
              saveAddress={ saveAddress }
            />
        );
    }

    renderOptionalCustomAddress() {
        const { isCustomAddressExpanded } = this.state;

        return (
            <div
              block="CheckoutAddressBook"
              elem="CustomAddressWrapper"
            >
                <button
                  block="CheckoutAddressBook"
                  elem="Button"
                  mods={ { isCustomAddressExpanded } }
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                  type="button"
                  onClick={ this.expandCustomAddress }
                >
                     <AddIcon />
                    { __('Add New Address') }
                </button>
            </div>
        );
    }

    renderErrorMsg() {
        const { showError } = this.props;

        if (!showError) {
            return null;
        }

        return (
            <>
            <div block="CheckoutAddressBook" elem="ErrorMsg">
                { __('Please choose your delivery destination in order to Place order or Change the Shipping Address.') }
            </div>
             <StoreSwitcherV2 key={ 1 } isCheckout />
            </>
        );
    }

    renderHeading() {
        const { isBilling } = this.props;

        if (isBilling) {
            return null;
        }

        return (
            <h2 block="Checkout" elem="Heading">
                { __('Delivery Address') }
            </h2>
        );
    }

    renderSignedInContent() {
        return (
            <div block="CheckoutAddressBook" elem="Wrapper">
                { this.renderAddressList() }
            </div>
        );
    }

    render() {
        return (
            <>
            <div block="CheckoutAddressBook">
                <div block="CheckoutAddressBook" elem="HeadingBlock">
                    { this.renderHeading() }
                    { this.renderOptionalCustomAddress() }
                </div>
                { this.renderContent() }
                { this.renderErrorMsg() }
            </div>
            { this.renderCustomAddress() }
            </>
        );
    }
}
export default CheckoutAddressBookComponent;
