/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-len */
import HeartIcon from 'SourceComponent/HeartIcon';
import { ProductWishlistButton as SourceProductWishlistButton } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

/** @namespace Tigerone/Component/ProductWishlistButton/Component */
export class ProductWishlistButtonComponent extends SourceProductWishlistButton {
    getTitle() {
        const { isInWishlist, isSignedIn, isEditing } = this.props;

        if (!isSignedIn) {
            return __('Please sign in first!');
        }

        if (isInWishlist) {
            return __('Remove from Wishlist');
        }

        if (isEditing) {
            return __('Move to Wishlist');
        }

        return __('Add to Wishlist');
    }

    renderButton() {
        const {
            isInWishlist, isDisabled, mix, isEditing
        } = this.props;

        if (isEditing) {
            return (
                <button
                  block="ProductWishlistButton"
                  elem="Button"
                  mods={ { isInWishlist, isDisabled } }
                  mix={ { block: 'Button', mix } }
                  title={ this.getTitle() }
                  onClick={ this.onClick }
                >
                   { this.getTitle() }
                </button>
            );
        }

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled } }
              mix={ { block: 'Button', mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                <HeartIcon isActive={ isInWishlist } />
            </button>
        );
    }
}
export default ProductWishlistButtonComponent;
