// TODO update this import when renamed
import { SET_ADDRESS, SET_SHIPPING_METHOD } from './Quotes.action';

/** @namespace Tigerone/Store/Quotes/Reducer/getInitialState */
export const getInitialState = () => ({
    selectedAddress: {},
    selectedShippingMethod: {}
});

/** @namespace Tigerone/Store/Quotes/Reducer/QuotesReducer */
export const QuotesReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_ADDRESS:
        const { address } = action;

        return {
            ...state,
            selectedAddress: address
        };

    case SET_SHIPPING_METHOD:
        const { method } = action;

        return {
            ...state,
            selectedShippingMethod: method
        };

    default:
        return state;
    }
};

export default QuotesReducer;
