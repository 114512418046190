/* eslint-disable import/prefer-default-export */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const NONE_SORT_OPTION_VALUE = 'none';
export const BEST_MATCH_SORT_OPTION_VALUE = 'position';

export const NONE_SORT_OPTION = {
    label: 'None',
    value: NONE_SORT_OPTION_VALUE
};
