/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { STOCK_TYPE } from 'Component/Product/Stock.config';
import { ProductAttributeValue as SourceProductAttributeValue } from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import { STRING_ONLY_ATTRIBUTE_CODES } from './ProductAttributeValue.config';

import './ProductAttributeValue.style';

/** @namespace Tigerone/Component/ProductAttributeValue/Component */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    renderStringValue(value, label, count, status = {}) {
        const {
            isFormattedAsText, isSelected, selectedVariant
        } = this.props;

        const {
            stock_status,
            salable_qty
        } = status;

        const isSwatch = label;

        if (isFormattedAsText) {
            return label || value || 'N/A';
        }

        if (!isSwatch) {
            return this.renderDropdown(value, count);
        }

        if (stock_status === STOCK_TYPE.IN_STOCK && salable_qty > 0) {
            return (
                <div
                  className="String_main"
                >
                    <div
                      block="ProductAttributeValue"
                      elem="String"
                      mods={ { isSelected } }
                      title={ label }
                    >
                        <span>{ value }</span>
                    </div>
                    <div block="ProductAttributeValue" elem="qty" mods={ { isSelected } }>
                        { selectedVariant ? `${__('Only')} ${selectedVariant?.salable_qty} ${__('left!')}` : null }
                    </div>
                </div>

            );
        } else {
            return (
                <div
                  className="String_main"
                >
                    <div
                      block="ProductAttributeValue"
                      elem="String"
                      mods={ { isSelected } }
                      title={ label }
                    >
                        <div block="outofstock-section_main">
                            <div block="outofstock-section">
                                <span block="Outofstock-value">
                                    { value }
                                </span>
                                <span block="Outofstock-label">
                                { __('out of stock!') }
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            );
        }
    }

    renderSelectAttribute() {
        const {
            attribute: { attribute_value, attribute_code, has_swatch }, variants = {}
        } = this.props;
        const attributeOption = this.getOptionLabel(attribute_value);
        const {
            label, labelText, count, swatch_data
        } = attributeOption;

        const {
            stock_status,
            salable_qty
        } = variants;

        // skip attributes without valid swatches
        if (!swatch_data && has_swatch) {
            return null;
        }

        if (!swatch_data || STRING_ONLY_ATTRIBUTE_CODES.includes(attribute_code)) {
            return this.renderStringValue(labelText || 'N/A', null, count);
        }

        const { value, type } = swatch_data;

        switch (type) {
        case '0':
            return this.renderStringValue(value, labelText, count, { stock_status, salable_qty });
        case '1':
            return this.renderColorValue(value, label);
        case '2':
            return this.renderImageValue(value, label);
        default:
            return this.renderStringValue(labelText || 'N/A', labelText, count);
        }
    }

    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (isFormattedAsText) {
            return (
                <div
                  block="ProductAttributeValue"
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </div>
            );
        }

        if (!showProductAttributeAsLink) {
            return (
                <span
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable } }
                  onClick={ this.clickHandler }
                  onKeyDown={ this.clickHandler }
                  role="link"
                  tabIndex="-1"
                  aria-hidden={ isNotAvailable }
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </span>
            );
        }

        return (
            <>
                { /* eslint-disable-next-line react/forbid-elements */ }
                <a
                  href={ href }
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable } }
                  onClick={ this.clickHandler }
                  aria-hidden={ isNotAvailable }
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </a>
            </>
        );
    }
}

export default ProductAttributeValueComponent;
