/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const ACCOUNT_INFORMATION_EDIT_URL = '/customer/account/edit';
export const ACCOUNT_FORGOT_PASSWORD_URL = '/customer/account/forgotpassword';
export const ACCOUNT_REGISTRATION_URL = '/customer/account/create';
export const ACCOUNT_LOGIN_URL = '/customer/account/login';
export const ACCOUNT_URL = '/customer/account';
export const MY_ACCOUNT_EDIT = '/customer/account/edit';
export const ACCOUNT_ORDER_URL = '/sales/order/view/order_id';
export const ACCOUNT_QUOTES_URL = '/negotiable_quote/quote/view/quote_id';
export const ACCOUNT_QUOTES_HISTORY = '/negotiable_quote/quote';
export const ACCOUNT_ORDER_PRINT_URL = '/sales/order/print/order_id';
export const ACCOUNT_ORDER_HISTORY = '/sales/order/history';
export const ACCOUNT_WISHLIST = '/wishlist';
export const CUSTOMER_ACCOUNT_USER_ROLE = 'TYPE_COMPANY_ADMIN';
// eslint-disable-next-line max-len
export const LOCKED_ACCOUNT_ERROR_MESSAGE = 'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.';
export const ACCOUNT_CREATE = '/customer/account/create';
