/* eslint-disable max-len */
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps
} from 'Component/MyAccountAddressTable/MyAccountAddressTable.container';
import {
    CheckoutAddressTableContainer as SourceCheckoutAddressTableContainer
} from 'SourceComponent/CheckoutAddressTable/CheckoutAddressTable.container';

/** @namespace Tigerone/Component/CheckoutAddressTable/Container */
export class CheckoutAddressTableContainer extends SourceCheckoutAddressTableContainer {

}
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressTableContainer);
