/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

/** @namespace Tigerone/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    // TODO implement logic
    renderContent() {
        const { children, contentMix, isClickOutside } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ this.hidePopUp }
              isClickOutside={ isClickOutside }
            >
                <div block="Popup" elem="Content" mix={ contentMix }>
                    <header block="Popup" elem="Header">
                        { this.renderTitle() }
                        { isClickOutside ? null : this.renderCloseButton() }
                    </header>
                    { children }
                </div>
            </ClickOutside>
        );
    }

    renderCloseButton() {
        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label="Close"
              onClick={ this.hidePopUp }
            >
                <CloseIcon />
            </button>
        );
    }
}

export default PopupComponent;
