import PropTypes from 'prop-types';

import {
    ImageContainer as SourceImageContainer
} from 'SourceComponent/Image/Image.container';
import { MixType, RefType } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

/** @namespace Tigerone/Component/Image/Container */
export class ImageContainer extends SourceImageContainer {
    static propTypes = {
        isPlaceholder: PropTypes.bool,
        src: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool
        ]),
        style: PropTypes.objectOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool
        ])),
        width: PropTypes.string,
        height: PropTypes.string,
        alt: PropTypes.string,
        ratio: PropTypes.oneOf([
            '4x3',
            '16x9',
            'square',
            'custom'
        ]),
        mix: MixType,
        className: PropTypes.string,
        imageRef: RefType,
        title: PropTypes.string,
        isPlain: PropTypes.bool,
        showIsLoading: PropTypes.bool,
        onImageLoad: PropTypes.func,
        onImageLoadFail: PropTypes.func
    };

    static defaultProps = {
        src: '',
        alt: '',
        ratio: 'square',
        mix: {},
        height: '',
        width: '',
        isPlaceholder: false,
        style: {},
        title: null,
        className: '',
        imageRef: noopFn,
        isPlain: false,
        showIsLoading: false,
        onImageLoad: noopFn,
        onImageLoadFail: noopFn
    };

    containerProps() {
        const { onImageLoadFail } = this.props;
        return {
            ...super.containerProps(),
            onImageLoadFail
        };
    }
}

export default ImageContainer;
