import ChevronIcon from 'SourceComponent/ChevronIcon';
import { BOTTOM, TOP } from 'SourceComponent/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'SourceComponent/ClickOutside';
import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';

/** @namespace Tigerone/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {
    renderNativeOption(option) {
        const {
            id,
            value,
            disabled,
            label,
            subLabel = '',
            isAvailable = true
        } = option;

        const { isDisabled } = this.props;

        return (
            <option
              key={ id }
              id={ id }
              value={ value }
              disabled={ disabled || isDisabled || !isAvailable }
            >
                { `${label} ${subLabel}` }
            </option>
        );
    }

    renderNativeSelect() {
        const {
            setRef, attr, events, isDisabled, options, handleSelectListOptionClick, isSelectedOptionAvailable
        } = this.props;

        return (
            <select
              block="FieldSelect"
              elem="Select"
              mods={ { isDisabled: !isSelectedOptionAvailable } }
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...attr }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...events }
              onChange={ handleSelectListOptionClick }
            >
                { options.map(this.renderNativeOption.bind(this)) }
            </select>
        );
    }

    renderOption(option) {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered,
            isAvailable = true
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick
        } = this.props;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isDisabled: !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered
              } }
              key={ id }
              /**
               * Added 'o' as querySelector does not work with
               * ids, that consist of numbers only
               */
              id={ `o${id}` }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onMouseDown={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onTouchStart={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { __(`${label}`) }
                { subLabel && (
                    <strong>
                        { __(` ${subLabel}`) }
                    </strong>
                ) }
            </li>
        );
    }

    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            style
        } = this.props;

        if (style) {
            return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${ id }_wrapper` }
                  block="FieldSelect"
                  mods={ { isExpanded } }
                  onClick={ handleSelectExpand }
                  onKeyPress={ handleSelectListKeyPress }
                  role="button"
                  tabIndex="0"
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                    <div block="FieldSelect" elem="Clickable">
                        { this.renderSortSelect() }
                        { this.renderNativeSelect() }
                        <ChevronIcon direction={ isExpanded ? TOP : BOTTOM } style />
                    </div>
                    { this.renderOptions() }
                </div>
            </ClickOutside>
            );
        }

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${ id }_wrapper` }
                  block="FieldSelect"
                  mods={ { isExpanded } }
                  onClick={ handleSelectExpand }
                  onKeyPress={ handleSelectListKeyPress }
                  role="button"
                  tabIndex="0"
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                    <div block="FieldSelect" elem="Clickable">
                        { this.renderSortSelect() }
                        { this.renderNativeSelect() }
                        <ChevronIcon direction={ isExpanded ? TOP : BOTTOM } />
                    </div>
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}
export default FieldSelectComponent;
