/* eslint-disable no-magic-numbers */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CheckoutCreditCardForm.style';

/** @namespace Tigerone/Component/CheckoutCreditCardForm/Component */
export class CheckoutCreditCardFormComponent extends PureComponent {
    static propTypes = {
        handleInputChange: PropTypes.func.isRequired,
        creditCardNumber: PropTypes.string.isRequired,
        expireDate: PropTypes.func.isRequired,
        cvc: PropTypes.string.isRequired,
        isValid: PropTypes.bool.isRequired,
        isValidCardDetails: PropTypes.bool.isRequired,
        cardTypeImg: PropTypes.string.isRequired,
        isCardAvailable: PropTypes.bool.isRequired,
        cvvMaxLength: PropTypes.bool.isRequired
    };

    renderErrorMesaage() {
        const { isValid, expireDate } = this.props;

        if (!isValid && expireDate) {
            return (
                <p block="CheckoutCreditCardForm" elem="errorMsg">
                    { __('Invalid Month/Year.') }
                </p>
            );
        }

        return null;
    }

    renderRequiredFieldMsg(value, isCardAvailable = true, field) {
        const { isValidCardDetails } = this.props;

        if (!isValidCardDetails && !value) {
            return (
                <p block="CheckoutCreditCardForm" elem="errorMsg">
                    { __('*This field is required!.') }
                </p>
            );
        }

        if (!isCardAvailable && value) {
            return (
                <p block="CheckoutCreditCardForm" elem="errorMsg">
                    { __('*Incorrect card number!.') }
                </p>
            );
        }

        if (field === 'cvc' && value) {
            if (!(value.length >= 3)) {
                return (
                    <p block="CheckoutCreditCardForm" elem="errorMsg">
                        { __('*Incorrect cvc number!.') }
                    </p>
                );
            }
        }

        return null;
    }

    renderFieldLabel(fieldType, label) {
        return (
            <label htmlFor={ fieldType } block="Field" elem="Label">
                { label }
            </label>
        );
    }

    render() {
        const {
            handleInputChange,
            creditCardNumber,
            expireDate,
            cvc,
            isValidCardDetails,
            cardTypeImg,
            isValid,
            isCardAvailable,
            cvvMaxLength
        } = this.props;

        return (
            <div block="CheckoutCreditCardForm">
                <h4>{ __('Credit Card Details') }</h4>
                <div
                  block="CheckoutCreditCardForm"
                  elem={ !isValidCardDetails && !creditCardNumber
                      ? 'cardNumberField Field_error'
                      : 'cardNumberField' }
                >
                        <input
                          type="text"
                          name="creditCardNumber"
                          value={ creditCardNumber }
                          className="form-control"
                          required
                          placeholder="xxxx xxxx xxxx xxx"
                          onChange={ handleInputChange }
                        />
                        { this.renderFieldLabel(
                            'creditCardNumber',
                            __('Credit Card Number')
                        ) }
                    <span>{ cardTypeImg }</span>
                </div>
                { this.renderRequiredFieldMsg(creditCardNumber, isCardAvailable) }

                <div block="CheckoutCreditCardForm" elem="dateAndCvc">
                    <div className="col-6">
                        <div
                          block="CheckoutCreditCardForm"
                          elem={ (!isValidCardDetails && !expireDate)
                                || (!isValid && expireDate)
                              ? 'dateField Field_error'
                              : 'dateField' }
                        >
                            <input
                              type="text"
                              name="expireDate"
                              value={ expireDate }
                              className="form-control"
                              pattern="\d\d/\d\d"
                              maxLength="5"
                              required
                              placeholder="MM/YY"
                              onChange={ handleInputChange }
                            />
                            { this.renderFieldLabel('expireDate', 'Expiry date') }
                        </div>
                        { this.renderErrorMesaage() }
                        { this.renderRequiredFieldMsg(expireDate) }
                    </div>
                    <div className="col-6">
                        <div
                          block="CheckoutCreditCardForm"
                          elem={ !isValidCardDetails && !cvc
                              ? 'cvcField Field_error'
                              : 'cvcField' }
                        >
                            <input
                              type="tel"
                              name="cvc"
                              value={ cvc }
                              className="form-control"
                              pattern="\d{3,4}"
                              required
                              placeholder={ cvvMaxLength === 4 ? '4321' : '321' }
                              maxLength={ cvvMaxLength }
                              onChange={ handleInputChange }
                            />
                            { this.renderFieldLabel('cvc', 'CVC') }
                        </div>
                        { this.renderRequiredFieldMsg(cvc, true, 'cvc') }
                    </div>
                </div>
            </div>
        );
    }
}

export default CheckoutCreditCardFormComponent;
