/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CheckoutPaymentsContainer as SourceCheckoutPaymentsContainer
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { Addresstype } from 'Type/Account.type';
import { PaymentMethodsType } from 'Type/Checkout.type';
import { DeviceType } from 'Type/Device.type';
import { TotalsType } from 'Type/MiniCart.type';

import CheckoutPayments from './CheckoutPayments.component';

/** @namespace Tigerone/Component/CheckoutPayments/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showError: (message) => dispatch(showNotification('error', message))
});

/** @namespace Tigerone/Component/CheckoutPayments/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    totals: state.CartReducer.cartTotals,
    email: state.CheckoutReducer.email,
    device: state.ConfigReducer.device
});

/** @namespace Tigerone/Component/CheckoutPayments/Container */
export class CheckoutPaymentsContainer extends SourceCheckoutPaymentsContainer {
     static propTypes = {
         onPaymentMethodSelect: PropTypes.func.isRequired,
         setOrderButtonEnableStatus: PropTypes.func.isRequired,
         paymentMethods: PaymentMethodsType.isRequired,
         totals: TotalsType.isRequired,
         email: PropTypes.string.isRequired,
         billingAddress: Addresstype.isRequired,
         showError: PropTypes.func.isRequired,
         device: DeviceType.isRequired,
         isValidCardDetails: PropTypes.bool.isRequired
     };

     containerProps() {
         const {
             billingAddress,
             paymentMethods,
             setOrderButtonEnableStatus,
             showError,
             device: {
                 isMobile
             } = {},
             isValidCardDetails
         } = this.props;

         const { selectedPaymentCode } = this.state;

         return {
             billingAddress,
             paymentMethods,
             selectedPaymentCode,
             setOrderButtonEnableStatus,
             showError,
             isMobile,
             isValidCardDetails
         };
     }

     render() {
         return (
             <CheckoutPayments
               { ...this.containerProps() }
               { ...this.containerFunctions }
             />
         );
     }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentsContainer);
