/** * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
export const UPDATE_SHIPPING_FIELDS = 'UPDATE_SHIPPING_FIELDS';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_AVAILABLE = 'UPDATE_EMAIL_AVAILABLE';
export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD';
export const SET_SHIPPING_METHODS = 'SET_SHIPPING_METHODS';
export const LAST_ADDRESS_UPDATED = 'LAST_ADDRESS_UPDATED';
export const UPDATE_TOTALS_LOAD_STATUS = 'UPDATE_TOTALS_LOAD_STATUS';
export const SET_CREDIT_CARD_DETAILS = 'SET_CREDIT_CARD_DETAILS';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';

/** @namespace Tigerone/Store/Checkout/Action/updateShippingFields */
export const updateShippingFields = (shippingFields) => ({
    type: UPDATE_SHIPPING_FIELDS,
    shippingFields
});

/** @namespace Tigerone/Store/Checkout/Action/updateEmail */
export const updateEmail = (email) => ({
    type: UPDATE_EMAIL,
    email
});

/** @namespace Tigerone/Store/Checkout/Action/updateEmailAvailable */
export const updateEmailAvailable = (isEmailAvailable) => ({
    type: UPDATE_EMAIL_AVAILABLE,
    isEmailAvailable
});

/** @namespace Tigerone/Store/Checkout/Action/setShippingMethod */
export const setShippingMethod = (code) => ({
    type: SET_SHIPPING_METHOD,
    code
});

/** @namespace Tigerone/Store/Checkout/Action/setPaymentMethods */
export const setPaymentMethods = (code) => ({
    type: SET_PAYMENT_METHOD,
    payload: code
});

/** @namespace Tigerone/Store/Checkout/Action/setAvailableShippingMethods */
export const setAvailableShippingMethods = (methods) => ({
    type: SET_SHIPPING_METHODS,
    methods
});

/** @namespace Tigerone/Store/Checkout/Action/addLastAddressUpdated */
export const addLastAddressUpdated = (address) => ({
    type: LAST_ADDRESS_UPDATED,
    address
});

/** @namespace Tigerone/Store/Checkout/Action/setCreditCardDetails */
export const setCreditCardDetails = (key, value) => ({
    type: SET_CREDIT_CARD_DETAILS,
    key,
    value
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Tigerone/Store/Checkout/Action/updateLoadStatus */
export const updateLoadStatus = (status) => ({
    type: UPDATE_TOTALS_LOAD_STATUS,
    isLoading: status
});
