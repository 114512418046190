/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable fp/no-let */
/* eslint-disable array-callback-return */
/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-debugger */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */

import AddToCart from 'Component/AddToCart';
import FieldContainer from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import ProductBundleOptions from 'Component/ProductBundleOptions';
import ProductConfigurableAttributes from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import TextPlaceholder from 'Component/TextPlaceholder';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    Product as SourceProduct
} from 'SourceComponent/Product/Product.component';
import { htmlParse } from 'Util/HtmlParse';
import { filterConfigurableOptions } from 'Util/Product';
import { VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import BuyNow from '../BuyNow';
import { PRODUCT_TYPE } from './Product.config';

import './Product.override.style';
/** @namespace Tigerone/Component/Product/Component */
export class ProductComponent extends SourceProduct {
    renderName(header = true, dynamic = false) {
        const { getActiveProduct, product: { name, sku } = {}, productName } = this.props;
        const nameToRender = dynamic ? productName : name;
        const { sku: ActiveProductSku, id: ActiveProductId } = getActiveProduct() || {};

        if (!header) {
            return (
                <p
                  data-productName={ nameToRender }
                  data-productSku={ ActiveProductSku }
                  data-productId={ ActiveProductId }
                  data-groupItemId={ sku }
                  block={ this.className }
                  elem="Name"
                >
                    <TextPlaceholder content={ nameToRender } length="medium" />
                </p>
            );
        }

        return (
            <h1
              data-productName={ nameToRender }
              data-productSku={ ActiveProductSku }
              data-productId={ ActiveProductId }
              data-groupItemId={ sku }
              block={ this.className }
              elem="Title"
              itemProp="name"
            >
                <TextPlaceholder content={ nameToRender } length="medium" />
            </h1>
        );
    }

    renderBrand() {
        const {
            product: {
                attributes: { brand: { attribute_value: brands, attribute_options = {} } = {} } = {}
            },
            isLoading
        } = this.props;

        const value = attribute_options[brands]?.label;

        const aTag = new RegExp(/<a[^>]*>([^<]+)<\/a>/g);
        const test = aTag.test(value);
        if (value) {
            if (test === true) {
                if (isLoading) {
                    return <TextPlaceholder length="long" />;
                }

                return (
                    <span block="Product" elem="BrandnameLink">{ htmlParse(value) }</span>
                );
            } else {
                const title = value.replace(/'/g, '');

                return (
                    <h4 block="Product" elem="BrandnameText">{ title }</h4>
                );
            }
        }
    }

    renderBrandLogo() {
        return null;
    }

    renderSku() {
        const { getActiveProduct } = this.props;
        const { sku } = getActiveProduct();

        return <span block={ this.className } elem="Sku" itemProp="sku">{ `SKU: ${sku}` }</span>;
    }

    renderSkuName() {
        const { product: { sku } = {} } = this.props;

        if (!sku) {
            return null;
        }

        return (
            <p block="ProductCard" elem="SkuName">
                <TextPlaceholder content={ `SKU: ${ sku }` } length="medium" />
            </p>
        );
    }

    renderBuyNowButton(layout = 'grid') {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            product: { sku }
        } = this.props;

        const {
            attributes: { zero_price_allowed: { attribute_value: zeroPriceAllowed } = {} } = {},
            price_range: { maximum_price: { final_price: { value } = {} } = {} } = {}
        } = getActiveProduct() || {};

        if (!sku || (!value && !parseInt(zeroPriceAllowed, 10))) {
            return null;
        }

        return (
            <BuyNow
              mix={ { block: this.className, elem: 'BuyNow' } }
              addToCart={ addToCart }
              isDisabled={ !inStock }
              isIconEnabled={ false }
              layout={ layout }
              quantity={ quantity }
              product={ getActiveProduct() }
            />
        );
    }

    // renderStatus() {
    //     const {
    //         product: {
    //             stock_status,
    //             sku,
    //             type_id,
    //             items,
    //             bundle_items
    //         },
    //         inStock
    //     } = this.props;

    //     if (!sku) {
    //         return null;
    //     }

    //     const size = items?.length ? items?.length : bundle_items?.length;
    //     let requiredCount = 0;
    //     let outOfStockCount = 0;
    //     let inStockCount = 0;
    //     const itemaData = items?.length ? items : bundle_items;
    //     itemaData?.map((item) => {
    //         if (!item.required) {
    //             requiredCount++;
    //         }
    //         item?.options?.map((option) => {
    //             if (option.product.stock_status === 'OUT_OF_STOCK') {
    //                 outOfStockCount++;
    //             } else {
    //                 inStockCount++;
    //             }
    //         });
    //     });

    //     let isBundleStockStatus = false;
    //     // let productOutOfStock = true;

    //     if (type_id === PRODUCT_TYPE.bundle) {
    //         if (requiredCount === 0 && outOfStockCount === size) {
    //             isBundleStockStatus = true;
    //         } else if (requiredCount === size && outOfStockCount === size) {
    //             isBundleStockStatus = true;
    //         } else if (requiredCount === 0 && inStockCount === size) {
    //             isBundleStockStatus = false;
    //         } else if (requiredCount === 0 && outOfStockCount < size) {
    //             isBundleStockStatus = true;
    //         }
    //     }

    //     const custom_stock_status = stock_status === 'IN_STOCK' || stock_status === 'IN STOCK' ? 'IN_STOCK' : 'OUT_OF_STOCK';
    //     if (type_id === PRODUCT_TYPE.configurable || type_id === PRODUCT_TYPE.bundle) {
    //         return (
    //             <span block={ this.className } elem={ `StockStatus ${ (isBundleStockStatus ? !isBundleStockStatus : inStock) ? 'IN_STOCK' : 'OUT_OF_STOCK' }` }>
    //                     { (isBundleStockStatus ? !isBundleStockStatus : inStock)
    //                         ? <TextPlaceholder content={ __('In Stock') } length="medium" />
    //                         : <TextPlaceholder content={ __('Out of Stock') } length="medium" /> }
    //             </span>
    //         );
    //     }

    //     return (
    //         <span block={ this.className } elem={ `StockStatus ${custom_stock_status}` }>
    //                 { (stock_status === 'IN_STOCK' || stock_status === 'IN STOCK')
    //                     ? <TextPlaceholder content={ __('In Stock') } length="medium" />
    //                     : <TextPlaceholder content={ __('Out of Stock') } length="medium" /> }
    //         </span>
    //     );
    // }
    // commented to check case

    renderStatus() {
        const {
            product: {
                stock_status,
                sku,
                type_id,
                items,
                bundle_items
            },
            isSearchPageCard,
            inStock
        } = this.props;

        if (!sku) {
            return null;
        }

        const itemData = items?.length ? items : bundle_items;
        let requiredInStockCount = 0;
        let requiredOutOfStockCount = 0;
        let nonRequiredInStock = false;
        let allNonRequiredOutOfStock = true;

        itemData?.forEach((item) => {
            let itemInStock = false;

            item?.options?.forEach((option) => {
                const isInStock = option.product.stock_status === 'IN_STOCK' || option.product.stock_status === 'IN STOCK';

                if (isInStock) {
                    itemInStock = true;
                    if (item.required) {
                        requiredInStockCount++;
                    } else {
                        nonRequiredInStock = true;
                    }
                } else if (item.required) {
                    requiredOutOfStockCount++;
                } else {
                    allNonRequiredOutOfStock = allNonRequiredOutOfStock && true;
                }
            });

            if (item.required && !itemInStock) {
                allNonRequiredOutOfStock = false;
            }
        });

        let isBundleStockStatus = false;

        if (type_id === PRODUCT_TYPE.bundle) {
            if (requiredInStockCount > 0) {
                isBundleStockStatus = false;
            } else if (requiredOutOfStockCount > 0 && allNonRequiredOutOfStock) {
                isBundleStockStatus = true;
            } else {
                isBundleStockStatus = requiredInStockCount === 0 && nonRequiredInStock === false;
            }
        }

        const customStockStatus = stock_status === 'IN_STOCK' || stock_status === 'IN STOCK' ? 'IN_STOCK' : 'OUT_OF_STOCK';

        if (isSearchPageCard || type_id === PRODUCT_TYPE.configurable || type_id === PRODUCT_TYPE.bundle) {
            return (
                <span block={ this.className } elem={ `StockStatus ${(isBundleStockStatus || !inStock) ? 'OUT_OF_STOCK' : 'IN_STOCK'}` }>
                    { (isBundleStockStatus || !inStock)
                        ? <TextPlaceholder content={ __('Out of Stock') } length="medium" />
                        : <TextPlaceholder content={ __('In Stock') } length="medium" /> }
                </span>
            );
        }

        return (
            <span block={ this.className } elem={ `StockStatus ${customStockStatus}` }>
                { (customStockStatus === 'IN_STOCK')
                    ? <TextPlaceholder content={ __('In Stock') } length="medium" />
                    : <TextPlaceholder content={ __('Out of Stock') } length="medium" /> }
            </span>
        );
    }

    renderCustomAndBundleOptions() {
        const { product: { type_id }, configFormRef } = this.props;

        return (
            <form ref={ configFormRef }>
                    { type_id === PRODUCT_TYPE.bundle && this.renderBundleOptions() }
                    { this.renderCustomizableOptions() }
            </form>
        );
    }

    renderBundleOptions() {
        const {
            product: {
                items
            } = {},
            updateSelectedValues
        } = this.props;
        let isRadio = false;
        let count = 0;

        items?.map((item) => {
            item?.options?.map((product) => {
                if (product?.product?.stock_status === 'OUT_OF_STOCK') {
                    count++;
                }
                if (product?.product?.stock_status === 'OUT_OF_STOCK' && !item.required) {
                    isRadio = false;
                }
            });
        });

        if (count === items?.length) {
            isRadio = false;
        }

        return (
            <ProductBundleOptions
              options={ items }
              updateSelectedValues={ updateSelectedValues }
              isRadio={ isRadio }
            />
        );
    }

    renderNotifyStockButton() {
        const { layout, notifyClick, product } = this.props;
        return (
            <button
              block="Button NotifyStock"
              mods={ { layout } }
              onClick={ () => notifyClick(product) }
            >
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 16.0001C1.45 16.0001 0.979333 15.8044 0.588 15.4131C0.196 15.0211 0 14.5501 0 14.0001V2.00006C0 1.45006 0.196 0.979394 0.588 0.588061C0.979333 0.196061 1.45 6.10352e-05 2 6.10352e-05H18C18.55 6.10352e-05 19.021 0.196061 19.413 0.588061C19.8043 0.979394 20 1.45006 20 2.00006V14.0001C20 14.5501 19.8043 15.0211 19.413 15.4131C19.021 15.8044 18.55 16.0001 18 16.0001H2ZM18 4.00006L10.525 8.67506C10.4417 8.72506 10.354 8.76239 10.262 8.78706C10.1707 8.81239 10.0833 8.82506 10 8.82506C9.91667 8.82506 9.82933 8.81239 9.738 8.78706C9.646 8.76239 9.55833 8.72506 9.475 8.67506L2 4.00006V14.0001H18V4.00006ZM10 7.00006L18 2.00006H2L10 7.00006ZM2 4.25006V2.77506V2.80006V2.78706V4.25006Z" fill="black" />
                </svg>
                <span>NOTIFY ME</span>
            </button>
        );
    }

    renderAddToCartButton(layout = GRID_LAYOUT) {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            product: { sku } = {}
        } = this.props;

        const {
            attributes: { zero_price_allowed: { attribute_value: zeroPriceAllowed } = {} } = {},
            price_range: { maximum_price: { final_price: { value } = {} } = {} } = {}
        } = getActiveProduct() || {};

        if (!sku || (!value && !parseInt(zeroPriceAllowed, 10))) {
            return null;
        }

        if (!inStock) {
            return <>{ this.renderNotifyStockButton() }</>;
        }

        return (
            <AddToCart
              mix={ { block: this.className, elem: 'AddToCart' } }
              addToCart={ addToCart }
              isDisabled={ !inStock }
              isIconEnabled={ false }
              layout={ layout }
              quantity={ quantity }
              product={ getActiveProduct() }
            />
        );
    }

    renderQuantityChanger() {
        const {
            quantity,
            minQuantity,
            maxQuantity,
            setQuantity,
            inStock,
            product: { type_id }
        } = this.props;

        if (type_id === PRODUCT_TYPE.grouped) {
            return null;
        }

        return (
            <FieldContainer
              type={ FIELD_TYPE.number }
              attr={ {
                  id: 'item_qty',
                  name: 'item_qty',
                  defaultValue: quantity,
                  max: maxQuantity,
                  min: minQuantity
              } }
              validationRule={ {
                  inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                  isRequired: true,
                  range: {
                      min: minQuantity,
                      max: maxQuantity
                  }
              } }
              isDisabled={ !inStock }
              vc
              gt
              mix={ { block: this.className, elem: 'Qty' } }
              events={ { onChange: setQuantity } }
              validateOn={ ['onChange'] }
            />
        );
    }

    renderConfigurableOptions(isQuickOrderSearch, saveConfigProduct) {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = {}, configurable_options: configurableOptions = {} },
            product,
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError
        } = this.props;

        if (type !== PRODUCT_TYPE.configurable) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="AttributesWrapper"
            >
                <ProductConfigurableAttributes
                    // eslint-disable-next-line no-magic-numbers
                  numberOfPlaceholders={ [2, 4] }
                  updateAddToCartTriggeredWithError={ updateAddToCartTriggeredWithError }
                  addToCartTriggeredWithError={ addToCartTriggeredWithError }
                  mix={ { block: this.className, elem: 'Attributes' } }
                  parameters={ parameters }
                  variants={ variants }
                  updateConfigurableVariant={ setActiveProduct }
                  configurable_options={ filterConfigurableOptions(configurableOptions, variants) }
                  isContentExpanded
                  inStock={ inStock }
                  showProductAttributeAsLink={ false }
                  isQuickOrderSearch={ isQuickOrderSearch }
                  saveConfigProduct={ saveConfigProduct }
                  parentProduct={ product }
                />
            </div>
        );
    }
}

export default ProductComponent;
