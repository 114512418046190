/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
// import BrowserDatabase from 'Util/BrowserDatabase';
import { getErrorMessage } from 'Util/Request';

import { CheckoutAddressPopupComponent } from './CheckoutAddressPopup.component';
import { CHECKOUT_ADDRESS_POPUP_ID } from './CheckoutAddressPopup.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Tigerone/Component/CheckoutAddressPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payload: state.PopupReducer.popupPayload[CHECKOUT_ADDRESS_POPUP_ID] || {},
    device: state.ConfigReducer.device
});

/** @namespace Tigerone/Component/CheckoutAddressPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showErrorNotification: (error) => dispatch(showNotification('error', getErrorMessage(error))),
    showSuccessNotification: (message) => dispatch(showNotification('success', message))
});

/** @namespace Tigerone/Component/CheckoutAddressPopup/Container */
export class CheckoutAddressPopupContainer extends PureComponent {
    state = {
        isLoading: false
    };

    containerProps() {
        const { payload, hideActiveOverlay, setNewFormAddress } = this.props;
        const { isLoading } = this.state;

        return {
            isLoading, payload, hideActiveOverlay, setNewFormAddress
        };
    }

    render() {
        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <CheckoutAddressPopupComponent { ...this.containerProps() } { ...this.containerFunctions } { ...this.props } />
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressPopupContainer);
